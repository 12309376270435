import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { CookieService } from 'ngx-cookie';
import { ToastrService } from 'ngx-toastr';
import { backend } from "../../environments/environment"
import { FormulaElement, MarketplaceIngredient, MarketplaceQuotation, MarketplaceQuotationTest, ProductDetails, Project, ProofFilesRequest, SearchQueryObject, SupplierForm, SupplierProfile, UserProfile } from '../app.models';
import * as CryptoJS from 'crypto-js';
import { Sort } from '@angular/material/sort';

@Injectable({
  providedIn: 'root'
})
export class BackendService {


  invDBURL: any = {
    getDbName: "database/get-user-database-id",
    getIngUploadFormat: "database/ingredient-upload-format",
    getIngrediantList: 'database/database-ingredient-list',
    getUserDBID: 'database/get-user-database-id',
    getPendingExcelUploadId: 'database/get-user-pending-excel-upload-id',
    getPendingExcelData: 'database/get-user-pending-excel-upload-data',
    uploadExcel: 'database/upload-excel',
    deleteExcelUpload: 'database/cancel-excel-upload',
    deleteDatabase: 'database/delete',
    removeIngrediant: 'database/remove-ingredient',
    insertIngrediant: 'database/insert-ingredient',
    removeMappedIng: 'database/remove-mapped-ingredient-from-excel-upload',
    addMappedIng: 'database/add-mapped-ingredient-to-excel-upload',
    submitExcel: 'database/submit-excel-upload'
  }

  constructor(
    private http: HttpClient,
    private cookieService: CookieService,
    private router: Router,
    private toastr: ToastrService
  ) { 
  }

  getHeaders(token: string, userType?: "supplier" | "brand"){
    let headers: any = {
      "Authorization": `JWT ${token}`
    }
    if(userType == "supplier")
      headers["Supplier"] = "1"
    return headers
  }

  async getUserId(){
    if(this.cookieService.hasKey("token")){
      return this.http.get(`${backend}get-user-id`, {
        headers: {
          "Authorization": `JWT ${this.cookieService.get("token")}`
        }
      }).toPromise()
    }
    else{
      return null
    }
  }

  async verifyToken(hideError?: boolean, userType: string = "brand") {
    let token: any = this.cookieService.get("token")
    if(userType=="supplier"){
      return this.verifySupplierToken()
    }
    if (token) {
      return await this.http.post(`${backend}auth/verify-token`, {
        token: token
      }).toPromise().then((res: any) => {
        if (res["status"] != 200) {
          this.toastr.warning("Your session has expired. Please login again.")
          return null;
        }
        let now: any = new Date()
        let exp: any = new Date(now.getFullYear(), now.getMonth(), now.getDate(), now.getHours(), now.getMinutes() + 20, now.getSeconds());
        this.cookieService.put("token", token, {
          expires: exp,
          sameSite: "lax"
        });
        return token;
      }, async err => {
        if (!hideError)
          this.toastr.error("An error occurred! Please try again later!")
        this.cookieService.remove("token");
        return null;
      })
    }
    else {
      return null;
    }
  }

  async verifySupplierToken(hideError?: boolean) {
    let token: any = this.cookieService.get("token")
    if (token) {
      return await this.http.post(`${backend}auth/supplier/verify-token`, {
        token: token
      }).toPromise().then((res: any) => {
        if (res["status"] != 200) {
          this.toastr.warning("Your session has expired. Please login again.")
          return null;
        }
        let now: any = new Date()
        let exp: any = new Date(now.getFullYear(), now.getMonth(), now.getDate(), now.getHours(), now.getMinutes() + 20, now.getSeconds());
        this.cookieService.put("token", token, {
          expires: exp,
          sameSite: "lax"
        });
        return token;
      }, err => {
        if (!hideError)
          this.toastr.error("An error occurred! Please try again later!")
        this.cookieService.remove("token");
        return null;
      })
    }
    else {
      return null;
    }
  }



  encryptSecretKey = "Maya@360-safety.monitor-krypto";
  encryption(data: any){
    return CryptoJS.AES.encrypt(data, this.encryptSecretKey).toString();
    
  }

  getPricingPlans() {
    return this.http.post(`${backend}config/pricing-plans`, {}).toPromise()
  }

  getSupplierPricingPlans() {
    return this.http.post(`${backend}config/supplier-pricing-plans`, {}).toPromise()
  }

  registerUser(userData: any) {
    return this.http.post(`${backend}auth/register`, userData).toPromise();
  }
  signInUser(credentials: any) {
    credentials.password = this.encryption(credentials.password);
    return this.http.post(`${backend}auth/sign_in`, credentials).toPromise();
  }

  registerSupplier(userData: any) {
    return this.http.post(`${backend}auth/supplier/register`, userData).toPromise();
  }

  signInSupplier(credentials: any) {
    return this.http.post(`${backend}auth/supplier/sign_in`, credentials).toPromise();
  }

  signOutUser(userType: "user" | "supplier") {
    return this.verifyToken(false, userType).then((token: any) => {
      if (token) {
        let headers: any = {
          "Authorization": `JWT ${token}`
        }
        let route: any = "auth/signout"
        if(userType == "supplier"){
          headers["Supplier"] = "1"
          route = "auth/supplier/signout"
        }
        return this.http.post(`${backend}${route}`, {}, {
          headers
        }).toPromise()
      } else {
        return null; // Add a return statement for the case when token is falsy
      }
    })
  }

  signOutSupplier() {
    return this.verifySupplierToken().then((token: any) => {
      if (token) {
        return this.http.post(`${backend}auth/supplier/signout`, {}, {
          headers: {
            "Authorization": `JWT ${token}`,
            "Supplier": "1"
          }
        }).toPromise()
      }
      else{
        return null
      }
    })
  }

  async getUserType() {
    let token: any = this.cookieService.get("token")
    if (token) {
      return this.http.post(`${backend}get-user-type`, {
        token: this.cookieService.get("token")
      }).toPromise()
    }
    else {
      return null;
    }
  }

  getUserProfile(hideError?: boolean, dontNavigateToLogin?: boolean) {
    return this.verifyToken().then((token: any) => {
      if (token) {
        return this.http.get(`${backend}get-user-profile`, {
          headers: {
            "Authorization": `JWT ${token}`
          }
        }).toPromise().then((res: any) => {
          return res
        }, err => {
          if (!hideError)
            this.toastr.error("An error occurred! Please try again later!")
          if(dontNavigateToLogin){
            this.cookieService.remove("token");
            return err
          }
        })
      } else {
        return null; // Add a return statement for the case when token is not present
      }
    })
  }

  getSupplierProfile(hideError?: boolean) {
    console.log("Calling verify token")
    return this.verifySupplierToken().then((token: any) => {
      if (token) {
        return this.http.get(`${backend}supplier/get-supplier-profile`, {
          headers: {
            "Authorization": `JWT ${token}`,
            "Supplier": "1"
          }
        }).toPromise().then((res: any) => {
          return res
        }, err => {
          if (!hideError)
            this.toastr.error("An error occurred! Please try again later!")
          this.cookieService.remove("token");
          this.router.navigate(["login"], {
            queryParams: {
              postLoginPath: this.router.url
    
            }
          });
          return err
        })
      }
      else{
        return null
      }
    })
  }

  getSupplierById(id: any) {
    return this.http.get(`${backend}supplier/${id}`, {
      headers: {
        "Supplier": "1"
      }
    }).toPromise().then((res: any) => {
      return res
    }, err => {
      return err
    })
  }

  getConfiguration() {
    return this.http.get(`${backend}get-application-config`).toPromise()
  }

  clearNotifications() {
    return this.verifyToken().then((token: any) => {
      if (token) {
        return this.http.post(`${backend}clear-notifications`, {}, {
          headers: {
            "Authorization": `JWT ${token}`
          }
        }).toPromise()
      }
      else{
        return null
      }
    })
  }

  clearSupplierNotifications() {
    return this.verifySupplierToken().then((token: any) => {
      if (token) {
        return this.http.post(`${backend}supplier/clear-notifications`, {}, {
          headers: {
            "Authorization": `JWT ${token}`,
            "Supplier": "1"
          }
        }).toPromise()
      }
      else{
        return null
      }
    })
  }

  getUserProfileDetails(userId: string) {
    return this.verifyToken().then((token: any) => {
      if (token) {
        return this.http.post(`${backend}get-user`, {
          userId: userId
        }, {
          headers: {
            "Authorization": `JWT ${token}`
          }
        }).toPromise()
      }
      else{
        return null
      }
    })
  }

  getAllUsers() {
    return this.verifyToken().then((token: any) => {
      if (token) {
        return this.http.get(`${backend}get-all-users`, {
          headers: {
            "Authorization": `JWT ${token}`
          }
        }).toPromise()
      }
      else{
        return null
      }
    })
  }

  deleteSearchHistory(user: UserProfile) {
    return this.verifyToken().then((token: any) => {
      if (token) {
        return this.http.post<any>(`${backend}delete-search-history`, {
          id: user._id,
          email: user.email
        }, {
          headers: {
            "Authorization": `JWT ${token}`
          }
        }).toPromise()
      }
      else{
        return null
      }
    })
  }

  getAllSearchedChemicals() {
    return this.verifyToken().then((token: any) => {
      if (token) {
        return this.http.get(`${backend}get-user-searches`, {
          headers: {
            "Authorization": `JWT ${token}`
          }
        }).toPromise()
      }
      else{
        return null
      }
    })
  }

  getAllSearchedChemicalsProdevIds() {
    return this.verifyToken().then((token: any) => {
      if (token) {
        return this.http.get(`${backend}get-user-searches-prodev-ids`, {
          headers: {
            "Authorization": `JWT ${token}`
          }
        }).toPromise()
      }
      else{
        return null
      }
    })
  }

  getAllProductSearchesProdevIds() {
    return this.verifyToken().then((token: any) => {
      if (token) {
        return this.http.get(`${backend}get-user-product-searches-prodev-ids`, {
          headers: {
            "Authorization": `JWT ${token}`
          }
        }).toPromise()
      }
      else{
        return null
      }
    })
  }

  getAllSearchedChemicalsForUser(userId: String, email: String) {
    return this.verifyToken().then((token: any) => {
      if (token) {
        return this.http.post<any>(`${backend}get-chemical-searches-for-user`, {
          userId: userId,
          email: email
        }, {
          headers: {
            "Authorization": `JWT ${token}`
          }
        }).toPromise()
      }
      else{
        return null
      }
    })
  }

  updateUserData(data: UserProfile) {
    return this.verifyToken().then(async res => {
      if (res) {
        return this.http.post(`${backend}update-user-profile`, data, {
          headers: {
            "Authorization": `JWT ${res}`
          }
        }).toPromise()
      }
      else{
        return null
      }
    })
  }

  updateSupplierProfile(data: any) {
    return this.verifySupplierToken().then(async res => {
      if (res) {
        return this.http.post(`${backend}supplier/update-profile`, data, {
          headers: {
            "Authorization": `JWT ${res}`,
            "Supplier": "1"
          }
        }).toPromise()
      }
      else{
        return null
      }
    })
  }

  getChemicalDetails(prodevId: number, searchDetails: any, productIngredient?: boolean) {
    return this.verifyToken().then(async res => {
      if (res) {
        return await this.http.post<any>(`${backend}get-chemical`, {
          prodevId: prodevId,
          searchDetails: searchDetails,
          productIngredient
        }, {
          headers: {
            "Authorization": `JWT ${res}`
          }
        }).toPromise()
      }
      else{
        return null
      }
    })
  }

  getMarketplaceIngredientByIdForBrands(ingredientId: any) {
    return this.verifyToken().then(async res => {
      if (res) {
        return await this.http.post<any>(`${backend}brand/get-marketpalce-ingredient-by-id`, {
          ingredientId
        }, {
          headers: {
            "Authorization": `JWT ${res}`
          }
        }).toPromise()
      }
      else{
        return null
      }
    })
  }

  registerForDemo(data: any) {
    return this.http.post(`${backend}register-for-demo`, data).toPromise()
  }

  getDashboardSearchHIstory(month: number, year: number, category: string) {
    return this.verifyToken().then(async res => {
      if (res) {
        return this.http.post<any>(`${backend}get-dashboard-search-history`, {
          year: year,
          month: month,
          category: category
        }, {
          headers: {
            "Authorization": `JWT ${res}`
          }
        }).toPromise()
      }
      else{
        return null
      }
    })
  }

  generateBrandOrder(selectedPlan: String) {
    return this.verifyToken().then(async res => {
      if (res) {
        return await this.http.post<any>(`${backend}create-order`, {
          selectedPlan: selectedPlan,
          currency: "INR"
        }, {
          headers: {
            "Authorization": `JWT ${res}`
          }
        }).toPromise()
      }
      else{
        return null
      }
    })
  }

  getAuthToken() {
    return this.cookieService.get("token")
  }

  getUserCount() {
    return this.http.get(`${backend}user-count`).toPromise()
  }

  sendOTP(email: string, isMarketplaceAccount: boolean) {
    return this.http.post(`${backend}auth/forgot-password`, {
      email: email,
      isMarketplaceAccount
    }).toPromise()
  }

  verifyOTP(email: string, otp: string, isMarketplaceAccount: boolean) {
    return this.http.post(`${backend}auth/forgot-password-verify-otp`, {
      email: email,
      otp: otp,
      isMarketplaceAccount
    }).toPromise()
  }

  changePassword(email: string, otp: string, newPassword: string, isMarketplaceAccount: boolean) {
    return this.http.post(`${backend}auth/forgot-password-reset-password`, {
      email: email,
      otp: otp,
      newPassword: newPassword,
      isMarketplaceAccount
    }).toPromise()
  }

  resendVerificationLink() {
    return this.verifyToken().then((token: any) => {
      if (token) {
        return this.http.get(`${backend}auth/resend-verification-link`, {
          headers: {
            "Authorization": `JWT ${token}`
          }
        }).toPromise()
      }
      else{
        return null
      }
    })
  }

  resendSupplierVerificationLink() {
    return this.verifySupplierToken().then((token: any) => {
      if (token) {
        return this.http.get(`${backend}auth/supplier/resend-verification-link`, {
          headers: {
            "Authorization": `JWT ${token}`,
            "Supplier": "1"
          }
        }).toPromise()
      }
      else{
        return null
      }
    })
  }

  verifyAccount(userId: string) {
    return this.http.post(`${backend}verify-account`, {
      userId: userId
    }).toPromise()
  }

  uploadProfileImageForBrands(fileList: FileList) {
    return this.verifyToken().then((token: any) => {
      if (token) {
        if (fileList.length > 0) {
          let file: File = fileList[0];
          let formData: FormData = new FormData();
          formData.append('file', file, file.name);
          return this.http.post(`${backend}upload-profile-photo`, formData, {
            headers: {
              'Authorization': `JWT ${token}`
            }
          }).toPromise()
        }
        else {
          return null;
        }
      }
      else{
        return null
      }
    })
  }

  uploadBlogImage(fileList: FileList) {
    return this.verifyToken().then((token: any) => {
      if (token) {
        if (fileList.length > 0) {
          let file: File = fileList[0];
          let formData: FormData = new FormData();
          formData.append('file', file, file.name);
          return this.http.post(`${backend}admin/upload-blog-image`, formData, {
            headers: {
              'Authorization': `JWT ${token}`
            }
          }).toPromise()
        }
        else {
          return null;
        }
      }
      else{
        return null
      }
    })
  }

  uploadProfileImageForSupplier(fileList: FileList) {
    return this.verifySupplierToken().then((token: any) => {
      if (token) {
        if (fileList.length > 0) {
          let file: File = fileList[0];
          let formData: FormData = new FormData();
          formData.append('file', file, file.name);
          return this.http.post(`${backend}supplier/upload-profile-photo`, formData, {
            headers: {
              'Authorization': `JWT ${token}`,
              "Supplier": '1'
            }
          }).toPromise()
        }
        else {
          return null;
        }
      }
      else{
        return null
      }
    })
  }

  downloadProfileImageForBrands() {
    return this.verifyToken().then((token: any) => {
      if (token) {
        return this.http.get(`${backend}download-profile-photo`, {
          headers: {
            'Authorization': `JWT ${token}`,
            Accept: "application/octet-stream"
          },
          responseType: 'blob'
        }).toPromise()
      }
      else{
        return null
      }
    })
  }

  downloadProfileImageForSuppliers() {
    return this.verifySupplierToken().then((token: any) => {
      if (token) {
        return this.http.post(`${backend}supplier/download-profile-photo`, {}, {
          headers: {
            'Authorization': `JWT ${token}`,
            'Accept': "application/octet-stream",
            "Supplier": "1"
          },
          responseType: 'blob'
        }).toPromise()
      }
      else{
        return null
      }
    })
  }

  downloadProofOfBenefitsClaimedForBrands(supplierId: string, formId: string, fileName: string, fileType: string) {
    return this.verifyToken().then((token: any) => {
      if (token) {
        console.log(supplierId, formId,fileName, fileType)
        return this.http.post(`${backend}download-proof-file`, {
          supplierId,
          formId,
          fileName,
          fileType
        }, {
          headers: {
            'Authorization': `JWT ${token}`
          },
          responseType: 'blob'
        }).toPromise()
      }
      else{
        return null
      }
    })
  }

  downloadProofOfBenefitsClaimedForSupplier(formId: string, fileName: string, fileType: string) {
    return this.verifySupplierToken().then((token: any) => {
      if (token) {
        return this.http.post(`${backend}supplier/download-proof-file`, {
          formId,
          fileName,
          fileType
        }, {
          headers: {
            'Authorization': `JWT ${token}`,
            'Supplier': '1'
          },
          responseType: 'blob'
        }).toPromise()
      }
      else{
        return null
      }
    })
  }

  submitFeedback(feedback: string, feedbackRatingValue: number) {
    return this.verifyToken().then((token: any) => {
      if (token) {
        return this.http.post(`${backend}submit-feedback`, {
          feedback: feedback,
          rating: feedbackRatingValue
        }, {
          headers: {
            'Authorization': `JWT ${token}`
          }
        }).toPromise()
      }
      else{
        return null
      }
    })
  }

  submitSupplierFeedback(feedback: string, feedbackRatingValue: number) {
    return this.verifySupplierToken().then((token: any) => {
      if (token) {
        return this.http.post(`${backend}marketplace/submit-feedback`, {
          feedback: feedback,
          rating: feedbackRatingValue
        }, {
          headers: {
            'Authorization': `JWT ${token}`,
            'Supplier': '1'
          }
        }).toPromise()
      }
      else{
        return null
      }
    })
  }

  downloadInvoice(orderId: any) {
    return this.verifyToken().then((token: any) => {
      if (token) {
        return this.http.post(`${backend}download-pdf-invoice`, { orderId }, {
          headers: {
            'Authorization': `JWT ${token}`
          },
          responseType: 'blob' as 'json'
        }).toPromise()
      }
      else{
        return null
      }
    })
  }

  getOrderDetails(orderId: string) {
    return this.verifyToken().then((token: any) => {
      if (token) {
        return this.http.post(`${backend}get-order-details`, {
          orderId: orderId
        }, {
          headers: {
            'Authorization': `JWT ${token}`
          }
        }).toPromise()
      }
      else{
        return null
      }
    })
  }

  changeEmail(newEmail: string) {
    return this.verifyToken().then((token: any) => {
      if (token) {
        return this.http.post(`${backend}change-email`, {
          newEmail: newEmail
        }, {
          headers: {
            'Authorization': `JWT ${token}`
          }
        }).toPromise().then((res: any) => {
          if (res) {
            let now: any = new Date()
            let exp: any = new Date(now.getFullYear(), now.getMonth(), now.getDate(), now.getHours(), now.getMinutes() + 20, now.getSeconds());
            this.cookieService.remove("token")
            this.cookieService.put("token", res["data"]["token"], {
              expires: exp,
              sameSite: "lax"
            });
            return res
          }
          return null
        })
      }
      else{
        return null
      }
    })
  }

  changeSupplierEmail(newEmail: string) {
    return this.verifySupplierToken().then((token: any) => {
      if (token) {
        return this.http.post(`${backend}supplier/change-email`, {
          newEmail: newEmail
        }, {
          headers: {
            'Authorization': `JWT ${token}`,
            "Supplier": "1"
          }
        }).toPromise().then((res: any) => {
          if (res) {
            let now: any = new Date()
            let exp: any = new Date(now.getFullYear(), now.getMonth(), now.getDate(), now.getHours(), now.getMinutes() + 20, now.getSeconds());
            this.cookieService.remove("token")
            this.cookieService.put("token", res["data"]["token"], {
              expires: exp,
              sameSite: "lax"
            });
            return res
          }
          return null
        })
      }
      else{
        return null
      }
    })
  }

  findSearchHistory(searchFilters: any, userId?: String) {
    return this.verifyToken().then((token: any) => {
      if (token) {
        return this.http.post(`${backend}user-filtered-search`, {
          searchQuery: searchFilters,
          userId: userId
        }, {
          headers: {
            'Authorization': `JWT ${token}`
          }
        }).toPromise()
      }
      else{
        return null
      }
    })
  }

  requestMoreAccounts(message: string) {
    return this.verifyToken().then((token: any) => {
      if (token) {
        return this.http.post(`${backend}get-more-accounts`, {
          message: message
        }, {
          headers: {
            'Authorization': `JWT ${token}`
          }
        }).toPromise()
      }
      else{
        return null
      }
    })
  }

  getAllowanceRequests() {
    return this.verifyToken().then((token: any) => {
      if (token) {
        return this.http.post(`${backend}get-allowance-request-users`, {}, {
          headers: {
            'Authorization': `JWT ${token}`
          }
        }).toPromise()
      }
      else{
        return null
      }
    })
  }


  acceptAllowanceRequest(email: string, userId: string, message: string) {
    return this.verifyToken().then((token: any) => {
      if (token) {
        return this.http.post(`${backend}accept-account-allowance`, {
          userId: userId,
          message: message,
          email: email
        }, {
          headers: {
            'Authorization': `JWT ${token}`
          }
        }).toPromise()
      }
      else{
        return null
      }
    })
  }


  rejectAllowanceRequest(email: string, userId: string, message: string) {
    return this.verifyToken().then((token: any) => {
      if (token) {
        return this.http.post(`${backend}reject-account-allowance`, {
          userId: userId,
          message: message,
          email: email
        }, {
          headers: {
            'Authorization': `JWT ${token}`
          }
        }).toPromise()
      }
      else{
        return null
      }
    })
  }

  getLoginInformationForUser(userId: string, pageNumber: number) {
    return this.verifyToken().then((token: any) => {
      if (token) {
        return this.http.post(`${backend}login-information`, {
          userId: userId,
          stage: pageNumber
        }, {
          headers: {
            'Authorization': `JWT ${token}`
          }
        }).toPromise()
      }
      else{
        return null
      }
    })
  }

  getAllProductsOfUser() {
    return this.verifyToken().then((token: any) => {
      if (token) {
        return this.http.post(`${backend}get-user-products`, {}, {
          headers: {
            'Authorization': `JWT ${token}`
          }
        }).toPromise()
      }
      else{
        return null
      }
    })
  }

  createProduct(productId: string, productDetails: any, formulaId: string, formula: any, formulaStage: number) {
    return this.verifyToken().then((token: any) => {
      if (token) {

        // let productDetails: any = productDetails

        return this.http.post(`${backend}create-product`, {
          productId,
          productDetails,
          formulaId,
          formula,
          formulaStage
        }, {
          headers: {
            'Authorization': `JWT ${token}`
          }
        }).toPromise()
      }
      else{
        return null
      }
    })
  }

  generateProductLog(productId: string, formulaId: string, action: string, actionFrom: string, details: any) {
    return this.verifyToken().then((token: any) => {
      if (token) {
        return this.http.post(`${backend}generate-product-log`, {
          productId,
          formulaId,
          action,
          actionFrom,
          details
        }, {
          headers: {
            'Authorization': `JWT ${token}`
          }
        }).toPromise()
      }
      else{
        return null
      }
    })
  }

  submitFormula(productId: string, formulaId: string) {
    return this.verifyToken().then((token: any) => {
      if (token) {
        return this.http.post(`${backend}submit-product`, {
          productId,
          formulaId
        }, {
          headers: {
            'Authorization': `JWT ${token}`
          }
        }).toPromise()
      }
      else{
        return null
      }
    })
  }

  getFormulation(productId: string, formulaId: string) {
    return this.verifyToken().then((token: any) => {
      if (token) {
        return this.http.post(`${backend}get-product-formulation`, {
          productId,
          formulaId
        }, {
          headers: {
            'Authorization': `JWT ${token}`
          }
        }).toPromise()
      }
      else{
        return null
      }
    })
  }

  updateFormulation(productId: string, formulaId: string, formulation: any) {
    return this.verifyToken().then((token: any) => {
      if (token) {
        return this.http.post(`${backend}update-product-formulation`, {
          productId,
          formulaId,
          formulation
        }, {
          headers: {
            'Authorization': `JWT ${token}`
          }
        }).toPromise()
      }
      else{
        return null
      }
    })
  }

  freezeFormula(productId: string, formulaId: string) {
    return this.verifyToken().then((token: any) => {
      if (token) {
        return this.http.post(`${backend}freeze-formula`, {
          productId,
          formulaId
        }, {
          headers: {
            'Authorization': `JWT ${token}`
          }
        }).toPromise()
      }
      else{
        return null
      }
    })
  }

  deleteFormula(projectId: string, productId: string, formulaId: string) {
    return this.verifyToken().then((token: any) => {
      if (token) {
        return this.http.post(`${backend}virtual-lab/delete-product-formula`, {
          projectId,
          productId,
          formulaId
        }, {
          headers: {
            'Authorization': `JWT ${token}`
          }
        }).toPromise()
      }
      else{
        return null
      }
    })
  }

  getLatestReportRequest(productId: string, formulaId: string) {
    return this.verifyToken().then((token: any) => {
      if (token) {
        return this.http.post(`${backend}get-latest-report-request`, {
          productId,
          formulaId
        }, {
          headers: {
            'Authorization': `JWT ${token}`
          }
        }).toPromise()
      }
      else{
        return null
      }
    })
  }

  requestSafetyDataReportOfProduct(productId: string, formulaId: string, productDetails: any, product: any, userProfile: UserProfile, status: string, message: string) {
    return this.verifyToken().then((token: any) => {
      if (token) {
        return this.http.post(`${backend}submit-safety-data-report`, {
          productId,
          formulaId,
          productDetails,
          product,
          userProfile,
          status,
          message
        }, {
          headers: {
            'Authorization': `JWT ${token}`
          }
        }).toPromise()
      }
      else{
        return null
      }
    })
  }

  getChemicalDetailsUntraced(prodevId: number) {
    return this.verifyToken().then(async res => {
      if (res) {
        return await this.http.post<any>(`${backend}get-chemical`, {
          prodevId: prodevId
        }, {
          headers: {
            "Authorization": `JWT ${res}`
          }
        }).toPromise()
      }
      else{
        return null
      }
    })
  }

  getChemicalDetailsForBrand(prodevId: number, searchDetails: any) {
    return this.verifyToken().then(async res => {
      if (res) {
        return await this.http.post<any>(`${backend}brand/get-chemical`, {
          prodevId,
          searchDetails
        }, {
          headers: {
            "Authorization": `JWT ${res}`
          }
        }).toPromise()
      }
      else{
        return null
      }
    })
  }

  getAllProductCategories() {
    return this.http.post<any>(`${backend}get-all-product-categories`, {}).toPromise()
  }

  getAllAIProductCategories() {
    return this.http.get<any>(`${backend}virtual-lab/get-all-product-categories`).toPromise()
  }

  getAllSupplier() {
    return this.http.post<any>(`${backend}get-all-suppliers`, {}).toPromise()
  }

  getAllSupplierForAdmin() {
    return this.verifyToken().then((token: any) => {
      if (token) {
        return this.http.post<any>(`${backend}get-all-suppliers-for-admin`, {}, {
          headers: {
            'Authorization': `JWT ${token}`
          }
        }).toPromise()
      }
      else{
        return null
      }
    })
  }

  getAllProductIds() {
    return this.verifyToken().then((token: any) => {
      if (token) {
        return this.http.post(`${backend}get-all-product-ids`, {}, {
          headers: {
            'Authorization': `JWT ${token}`
          }
        }).toPromise()
      }
      else{
        return null
      }
    })
  }

  getProductsForAdmin(
    userId: string,
    productId: string,
    primaryOrRefined: string,
    reducedOrReplaced: string,
    productStatus: string,
    selectedProductCategory: string,
    selectedProductType: string,
    selectedArticleCategory: string,
    selectedUseCategory: string
  ) {
    return this.verifyToken().then((token: any) => {
      if (token) {
        return this.http.post(`${backend}get-products-for-admin`, {
          userId,
          productId,
          primaryOrRefined,
          reducedOrReplaced,
          productStatus,
          selectedProductCategory,
          selectedProductType,
          selectedArticleCategory,
          selectedUseCategory
        }, {
          headers: {
            'Authorization': `JWT ${token}`
          }
        }).toPromise()
      }
      else{
        return null
      }
    })
  }

  getProductFormula(productId: string, formulaId: string) {
    return this.verifyToken().then((token: any) => {
      if (token) {
        return this.http.post(`${backend}get-product-formula-admin`, {
          productId,
          formulaId
        }, {
          headers: {
            'Authorization': `JWT ${token}`
          }
        }).toPromise()
      }
      else{
        return null
      }
    })
  }

  getAllSDRRequestsOfUser(userId: string, productId: string, formulaId: string) {
    return this.verifyToken().then((token: any) => {
      if (token) {
        return this.http.post(`${backend}get-sdr-requests-of-user`, {
          userId,
          productId,
          formulaId
        }, {
          headers: {
            'Authorization': `JWT ${token}`
          }
        }).toPromise()
      }
      else{
        return null
      }
    })
  }

  generateSDRReport(userId: string, productId: string, formulaId: string, reportId: any, reportRequestId: any) {
    return this.verifyToken().then((token: any) => {
      if (token) {
        return this.http.post(`${backend}generate-sdr-report`, {
          userId,
          productId,
          formulaId,
          reportId,
          reportRequestId
        }, {
          headers: {
            'Authorization': `JWT ${token}`
          }
        }).toPromise()
      }
      else{
        return null
      }
    })
  }

  cloneSDRReport(oldReportRequestId: string, newReportRequestId: string, newReport: boolean, newReportId: string) {
    return this.verifyToken().then((token: any) => {
      if (token) {
        return this.http.post(`${backend}clone-sdr-report`, {
          oldReportRequestId,
          newReportRequestId,
          newReport,
          newReportId
        }, {
          headers: {
            'Authorization': `JWT ${token}`
          }
        }).toPromise()
      }
      else{
        return null
      }
    })
  }

  getSDRReport(userId: string, productId: string, formulaId: string, reportId: string) {
    return this.verifyToken().then((token: any) => {
      if (token) {
        return this.http.post(`${backend}get-sdr-report`, {
          userId,
          productId,
          formulaId,
          reportId
        }, {
          headers: {
            'Authorization': `JWT ${token}`
          }
        }).toPromise()
      }
      else{
        return null
      }
    })
  }

  saveSDRReport(userId: string, productId: string, formulaId: string, reportId: string, data: any) {
    return this.verifyToken().then((token: any) => {
      if (token) {
        return this.http.post(`${backend}save-sdr-report`, {
          userId,
          productId,
          formulaId,
          reportId,
          data
        }, {
          headers: {
            'Authorization': `JWT ${token}`
          }
        }).toPromise()
      }
      else{
        return null
      }
    })
  }

  sendSDRReport(reportReqId: string) {
    return this.verifyToken().then((token: any) => {
      if (token) {
        return this.http.post(`${backend}send-sdr-report`, {
          reportReqId
        }, {
          headers: {
            'Authorization': `JWT ${token}`
          }
        }).toPromise()
      }
      else{
        return null
      }
    })
  }

  approveSDRReport(reportReqId: string) {
    return this.verifyToken().then((token: any) => {
      if (token) {
        return this.http.post(`${backend}approve-sdr-report`, {
          reportReqId
        }, {
          headers: {
            'Authorization': `JWT ${token}`
          }
        }).toPromise()
      }
      else{
        return null
      }
    })
  }

  rejectSDRReport(reportReqId: string, message: any) {
    return this.verifyToken().then((token: any) => {
      if (token) {
        return this.http.post(`${backend}reject-sdr-report`, {
          reportReqId,
          message: message
        }, {
          headers: {
            'Authorization': `JWT ${token}`
          }
        }).toPromise()
      }
      else{
        return null
      }
    })
  }

  getAdminChemical(prodevId: any) {
    return this.verifyToken().then((token: any) => {
      if (token) {
        return this.http.post(`${backend}admin/get-chemical`, {
          prodevId
        }, {
          headers: {
            'Authorization': `JWT ${token}`
          }
        }).toPromise()
      }
      else{
        return null
      }
    })
  }

  generateScoreAndHealthConcerns(chemical: any) {
    return this.verifyToken().then((token: any) => {
      if (token) {
        return this.http.post(`${backend}admin/generate-score-and-health-concerns`, {
          chemical
        }, {
          headers: {
            'Authorization': `JWT ${token}`
          }
        }).toPromise()
      }
      else{
        return null
      }
    })
  }

  saveIngredient(chemical: any) {
    return this.verifyToken().then((token: any) => {
      if (token) {
        return this.http.post(`${backend}admin/save-ingredient`, {
          chemical
        }, {
          headers: {
            'Authorization': `JWT ${token}`
          }
        }).toPromise()
      }
      else{
        return null
      }
    })
  }

  deleteIngredient(prodevId: any) {
    return this.verifyToken().then((token: any) => {
      if (token) {
        return this.http.post(`${backend}admin/delete-ingredient`, {
          prodevId
        }, {
          headers: {
            'Authorization': `JWT ${token}`
          }
        }).toPromise()
      }
      else{
        return null
      }
    })
  }

  getVLStatistics() {
    return this.verifyToken().then((token: any) => {
      if (token) {
        return this.http.post(`${backend}get-vl-statistics`, {}, {
          headers: {
            'Authorization': `JWT ${token}`
          }
        }).toPromise()
      }
      else{
        return null
      }
    })
  }

  getProductStatistics(productId: any) {
    return this.verifyToken().then((token: any) => {
      if (token) {
        return this.http.post(`${backend}get-product-statistics`, {
          productId
        }, {
          headers: {
            'Authorization': `JWT ${token}`
          }
        }).toPromise()
      }
      else{
        return null
      }
    })
  }

  getFreePlan() {
    return this.verifyToken().then((token: any) => {
      if (token) {
        return this.http.get(`${backend}get-free-plan`, {
          headers: {
            'Authorization': `JWT ${token}`
          }
        }).toPromise()
      }
      else{
        return null
      }
    })
  }

  getNumberOfFormulas(productId: string) {
    return this.verifyToken().then((token: any) => {
      if (token) {
        return this.http.post(`${backend}get-no-of-formulas`, {
          productId
        }, {
          headers: {
            'Authorization': `JWT ${token}`
          }
        }).toPromise()
      }
      else{
        return null
      }
    })
  }

  downloadSDR(sdr: any) {
    return this.verifyToken().then((token: any) => {
      if (token) {
        return this.http.post(`${backend}pdf-sdr`, {
          sdr: sdr
        }, {
          headers: {
            'Authorization': `JWT ${token}`
          },
          responseType: 'blob' as 'json'
        }).toPromise()
      }
      else{
        return null
      }
    })
  }

  addUseFunctionMismatchData(
    prodevId: number,
    originalUF: string[],
    inputUF: string[],
    combinedUseFunctions: boolean,
    productId: string,
    formulaId: string
  ) {
    return this.verifyToken().then((token: any) => {
      if (token) {
        return this.http.post(`${backend}use-function-mismatch`, {
          prodevId,
          originalUF,
          inputUF,
          combinedUseFunctions,
          productId,
          formulaId
        }, {
          headers: {
            'Authorization': `JWT ${token}`
          },
          responseType: 'blob' as 'json'
        }).toPromise()
      }
      else{
        return null
      }
    })
  }

  getProductCategoriesObject() {
    return this.verifyToken().then((token: any) => {
      if (token) {
        return this.http.post(`${backend}admin/get-product-categories`, {}, {
          headers: {
            'Authorization': `JWT ${token}`
          }
        }).toPromise()
      }
      else{
        return null
      }
    })
  }

  setProductCategories(data: any) {
    return this.verifyToken().then((token: any) => {
      if (token) {
        return this.http.post(`${backend}admin/set-product-categories`, { data }, {
          headers: {
            'Authorization': `JWT ${token}`
          }
        }).toPromise()
      }
      else{
        return null
      }
    })
  }

  saveTrialVersionLimits(ingredientResearchLimit: any, productIngredientResearchLimit: any, productLimit: any, applyToExistingUser: boolean) {
    return this.verifyToken().then((token: any) => {
      if (token) {
        return this.http.post(`${backend}admin/save-trial-version-limits`, {
          ingredientResearchLimit,
          productIngredientResearchLimit,
          productLimit,
          applyToExistingUser
        }, {
          headers: {
            'Authorization': `JWT ${token}`
          }
        }).toPromise()
      }
      else{
        return null
      }
    })
  }

  savePlanDetails(allPlans: any[], applyToExistingUser: boolean) {
    return this.verifyToken().then((token: any) => {
      if (token) {
        return this.http.post(`${backend}admin/save-plans`, {
          allPlans,
          applyToExistingUser
        }, {
          headers: {
            'Authorization': `JWT ${token}`
          }
        }).toPromise()
      }
      else{
        return null
      }
    })
  }

  bulkSearch(data: any) {
    return this.verifyToken().then((token: any) => {
      if (token) {
        return this.http.post(`${backend}admin/bulk-search`, {
          data
        }, {
          headers: {
            'Authorization': `JWT ${token}`,
          },
          responseType: 'blob'
        }).toPromise()
      }
      else{
        return null
      }
    })
  }

  updateIngredientsMultiple(data: any) {
    return this.verifyToken().then((token: any) => {
      if (token) {
        return this.http.post(`${backend}admin/update-ingredients-multiple`, {
          data
        }, {
          headers: {
            'Authorization': `JWT ${token}`
          }
        }).toPromise()
      }
      else{
        return null
      }
    })
  }

  updateUseFunctionsMultiple(data: any) {
    return this.verifyToken().then((token: any) => {
      if (token) {
        return this.http.post(`${backend}admin/update-use-functions-multiple`, {
          data
        }, {
          headers: {
            'Authorization': `JWT ${token}`
          }
        }).toPromise()
      }
      else{
        return null
      }
    })
  }

  getAllUserOrders() {
    return this.verifyToken().then((token: any) => {
      if (token) {
        return this.http.post(`${backend}get-user-orders`, {}, {
          headers: {
            'Authorization': `JWT ${token}`
          }
        }).toPromise()
      }
      else{
        return null
      }
    })
  }

  getAllRegions() {
    return this.http.get(`${backend}get-all-regions`).toPromise()
  }

  completeGuidedTourStage(stageId: string) {
    return this.verifyToken().then((token: any) => {
      if (token) {
        return this.http.post(`${backend}complete-tour-stage`, { stageId }, {
          headers: {
            'Authorization': `JWT ${token}`
          }
        }).toPromise()
      }
      else{
        return null
      }
    })
  }

  skipTour() {
    return this.verifyToken().then((token: any) => {
      if (token) {
        return this.http.post(`${backend}skip-tour`, {}, {
          headers: {
            'Authorization': `JWT ${token}`
          }
        }).toPromise()
      }
      else{
        return null
      }
    })
  }

  generateSupplierOrder(selectedPlan: String) {
    return this.verifySupplierToken().then(async res => {
      if (res) {
        return await this.http.post<any>(`${backend}supplier/create-order`, {
          selectedPlan: selectedPlan,
          currency: "INR"
        }, {
          headers: {
            "Authorization": `JWT ${res}`,
            'Supplier': '1'
          }
        }).toPromise()
      }
      else{
        return null
      }
    })
  }

  createSupplierForm(tradeName: string) {
    return this.verifySupplierToken().then((token: any) => {
      if (token) {
        return this.http.post(`${backend}supplier/create-ingredient-form`, {
          tradeName: tradeName
        }, {
          headers: {
            'Authorization': `JWT ${token}`,
            'Supplier': '1'
          }
        }).toPromise()
      }
      else{
        return null
      }
    })
  }

  retrieveSupplierForm(formId: string) {
    return this.verifySupplierToken().then((token: any) => {
      if (token) {
        return this.http.post(`${backend}supplier/retrieve-ingredient-form`, {
          formId: formId
        }, {
          headers: {
            'Authorization': `JWT ${token}`,
            'Supplier': '1'
          }
        }).toPromise()
      }
      else{
        return null
      }
    })
  }

  getAllSupplierForms(pageNo: number, sort: Sort, filters: {tradeNameQuery: string, status: string[]}) {
    return this.verifySupplierToken().then((token: any) => {
      if (token) {
        return this.http.post<MarketplaceIngredient[]>(`${backend}es/marketplace/get-all-ingredients`, {
          pageNo,
          sort,
          filters
        }, {
          headers: {
            'Authorization': `JWT ${token}`,
            'Supplier': '1'
          }
        }).toPromise()
      }
      else{
        return null
      }
    })
  }

  updateSupplierForm(formId: string, formData: any, removeFiles?: string[], isSubmitTrue?: boolean) {
    return this.verifySupplierToken().then((token: any) => {
      let formdata: any = new FormData()
      formdata.append('data', JSON.stringify(formData))
      formdata.append('isSubmitTrue', isSubmitTrue ? 'true' : 'false')
      formdata.append('formId', formId)
      if (removeFiles && removeFiles.length > 0)
        formdata.append('removeFiles', JSON.stringify(removeFiles))
      if (token) {
        return this.http.post(`${backend}supplier/update-ingredient-form`, formdata, {
          headers: {
            'Authorization': `JWT ${token}`,
            'Supplier': '1',
          }
        }).toPromise()
      }
      else{
        return null
      }
    })
  }

  uploadSupplierFormProof(formId: string, file: File) {
    return this.verifySupplierToken().then((token: any) => {
      let formdata: any = new FormData()
      formdata.append('formId', formId)
      formdata.append("file", file)
      if (token) {
        return this.http.post(`${backend}supplier/upload-ingredient-form-proof`, formdata, {
          headers: {
            'Authorization': `JWT ${token}`,
            'Supplier': '1',
          }
        }).toPromise()
      }
      else{
        return null
      }
    })
  }

  submitSupplierFormForReview(formId: string) {
    return this.verifySupplierToken().then((token: any) => {
      if (token) {
        return this.http.post(`${backend}supplier/submit-ingredient-form-for-review`, {
          formId: formId
        }, {
          headers: {
            'Authorization': `JWT ${token}`,
            'Supplier': '1'
          }
        }).toPromise()
      }
      else{
        return null
      }
    })
  }

  getCertificationOptions(userType?: "supplier" | "brand"){
    return this.verifyToken(false, userType).then((token: any) => {
      let headers: any = this.getHeaders(token, userType)
      if (token) {
        return this.http.get(`${backend}${userType=="supplier"? "supplier/" : ""}get-certifications`, {
          headers: headers
        }).toPromise()
      }
      else{
        return null
      }
    })
  }

  getSupplierProductCategories() {
    return this.verifySupplierToken().then((token: any) => {
      if (token) {
        return this.http.post(`${backend}supplier/get-product-categories`, {}, {
          headers: {
            'Authorization': `JWT ${token}`,
            'Supplier': '1'
          }
        }).toPromise()
      }
      else{
        return null
      }
    })
  }

  getChemicalGroups() {
    return this.verifySupplierToken().then((token: any) => {
      if (token) {
        return this.http.post(`${backend}supplier/get-chemical-groups`, {}, {
          headers: {
            'Authorization': `JWT ${token}`,
            'Supplier': '1'
          }
        }).toPromise()
      }
      else{
        return null
      }
    })
  }


  getChemicalGroupsForBrands() {
    return this.verifyToken().then((token: any) => {
      if (token) {
        return this.http.post(`${backend}get-chemical-groups`, {}, {
          headers: {
            'Authorization': `JWT ${token}`
          }
        }).toPromise()
      }
      else{
        return null
      }
    })
  }

  searchByChemicalName(searchQuery: string, user: string = "supplier") {
    return this.verifyToken(false, user).then(async res => {
      if (res) {
        let headers: any = {
          "Authorization": `JWT ${res}`
        }
        if(user == "supplier")
          headers["Supplier"] = '1'
        return this.http.post<any>(`${backend}find-chemical`, {
          chemicalName: searchQuery
        }, {
          headers: headers
        }).toPromise()
      }
      else {
        return null
      }
    })
  }

  getAdminAllSupplierForms() {
    return this.verifyToken().then((token: any) => {
      if (token) {
        return this.http.post(`${backend}admin/get-all-supplier-form`, {}, {
          headers: {
            'Authorization': `JWT ${token}`
          }
        }).toPromise()
      }
      else{
        return null
      }
    })
  }

  adminAcceptSupplierForm(formId: string, data: SupplierForm) {
    return this.verifyToken().then((token: any) => {
      if (token) {
        return this.http.post(`${backend}admin/accept-supplier-form`, {
          formId: formId,
          formData: data
        }, {
          headers: {
            'Authorization': `JWT ${token}`
          }
        }).toPromise()
      }
      else{
        return null
      }
    })
  }

  adminRejectSupplierForm(formId: string, data: SupplierForm) {
    return this.verifyToken().then((token: any) => {
      if (token) {
        return this.http.post(`${backend}admin/reject-supplier-form`, {
          formId: formId,
          formData: data
        }, {
          headers: {
            'Authorization': `JWT ${token}`
          }
        }).toPromise()
      }
      else{
        return null
      }
    })
  }

  requestQuoteFromSupplier(formId: any, formData: any) {
    return this.verifyToken().then(async res => {
      if (res) {
        return this.http.post<any>(`${backend}sumbit-supplier-quote-request`, {
          formId,
          formData
        }, {
          headers: {
            "Authorization": `JWT ${res}`
          }
        }).toPromise()
      }
      else {
        return null
      }
    })
  }

  requestSampleFromSupplier(formId: any, formData: any) {
    return this.verifyToken().then(async res => {
      if (res) {
        return this.http.post<any>(`${backend}sumbit-supplier-samples-request`, {
          formId,
          formData
        }, {
          headers: {
            "Authorization": `JWT ${res}`
          }
        }).toPromise()
      }
      else {
        return null
      }
    })
  }

  searchByTradeName(tradeName: any) {
    return this.verifyToken().then(async res => {
      if (res) {
        return this.http.post<any>(`${backend}search-by-trade-name`, {
          tradeName,
        }, {
          headers: {
            "Authorization": `JWT ${res}`
          }
        }).toPromise()
      }
      else {
        return null
      }
    })
  }

  getSupplierFormsForBrands(
    name: string,
    suppliers: any[],
    selectedProductCategories: any[],
    verifiedSupplier: boolean,
    pageNumber: number
  ) {
    return this.verifyToken().then(async res => {
      if (res) {
        return this.http.post<any>(`${backend}get-supplier-ingredients-for-brands`, {
          name,
          suppliers,
          productCategories: selectedProductCategories,
          verifiedSupplier,
          pageNumber
        }, {
          headers: {
            "Authorization": `JWT ${res}`
          }
        }).toPromise()
      }
      else {
        return null
      }
    })
  }

  getSupplierQuoteRequests(pageNumber: number) {
    return this.verifySupplierToken().then((token: any) => {
      if (token) {
        return this.http.post(`${backend}supplier/get-quote-requests`, {
          pageNumber
        }, {
          headers: {
            "Authorization": `JWT ${token}`,
            "Supplier": "1"
          }
        }).toPromise()
      }
      else{
        return null
      }
    })
  }


  getSupplierSampleRequests(pageNumber: number) {
    return this.verifySupplierToken().then((token: any) => {
      if (token) {
        return this.http.post(`${backend}supplier/get-sample-requests`, { pageNumber }, {
          headers: {
            "Authorization": `JWT ${token}`,
            "Supplier": "1"
          }
        }).toPromise()
      }
      else{
        return null
      }
    })
  }

  getSupplierQuoteRequestById(id: string) {
    return this.verifySupplierToken().then((token: any) => {
      if (token) {
        return this.http.post(`${backend}supplier/get-quote-request-by-id`, { id }, {
          headers: {
            "Authorization": `JWT ${token}`,
            "Supplier": "1"
          }
        }).toPromise()
      }
      else{
        return null
      }
    })
  }

  getSupplierSampleRequestById(id: string) {
    return this.verifySupplierToken().then((token: any) => {
      if (token) {
        return this.http.post(`${backend}supplier/get-sample-request-by-id`, { id }, {
          headers: {
            "Authorization": `JWT ${token}`,
            "Supplier": "1"
          }
        }).toPromise()
      }
      else{
        return null
      }
    })
  }

  acceptSupplierQuoteRequest(id: string) {
    return this.verifySupplierToken().then((token: any) => {
      if (token) {
        return this.http.post(`${backend}supplier/accept-quote-request`, { id }, {
          headers: {
            "Authorization": `JWT ${token}`,
            "Supplier": "1"
          }
        }).toPromise()
      }
      else{
        return null
      }
    })
  }

  rejectSupplierQuoteRequest(id: string) {
    return this.verifySupplierToken().then((token: any) => {
      if (token) {
        return this.http.post(`${backend}supplier/reject-quote-request`, { id }, {
          headers: {
            "Authorization": `JWT ${token}`,
            "Supplier": "1"
          }
        }).toPromise()
      }
      else{
        return null
      }
    })
  }

  closeSupplierSampleRequest(id: string, closingMessage: string) {
    return this.verifySupplierToken().then((token: any) => {
      if (token) {
        return this.http.post(`${backend}supplier/close-sample-request`, { id, closingMessage }, {
          headers: {
            "Authorization": `JWT ${token}`,
            "Supplier": "1"
          }
        }).toPromise()
      }
      else{
        return null
      }
    })
  }

  closeSupplierQuoteRequest(id: string, closingMessage: string) {
    return this.verifySupplierToken().then((token: any) => {
      if (token) {
        return this.http.post(`${backend}supplier/close-quote-request`, { id, closingMessage }, {
          headers: {
            "Authorization": `JWT ${token}`,
            "Supplier": "1"
          }
        }).toPromise()
      }
      else{
        return null
      }
    })
  }

  getSupplierProfileForAdmin(id: String) {
    return this.verifyToken().then((token: any) => {
      if (token) {
        return this.http.post<any>(`${backend}get-supplier-profile-for-admin`, { id }, {
          headers: {
            'Authorization': `JWT ${token}`
          }
        }).toPromise()
      }
      else{
        return null
      }
    })
  }

  deleteSupplierIngredient(formId: string) {
    return this.verifySupplierToken().then((token: any) => {
      if (token) {
        return this.http.post(`${backend}supplier/delete-supplier-ingredient`, {
          formId
        }, {
          headers: {
            'Authorization': `JWT ${token}`,
            "Supplier": "1"
          }
        }).toPromise()
      }
      else{
        return null
      }
    })
  }

  getOpenSamplesAndQuoteRequestsCount(formId: string) {
    return this.verifySupplierToken().then((token: any) => {
      if (token) {
        return this.http.post(`${backend}supplier/get-open-samples-and-quotes-requests-count`, {
          formId
        }, {
          headers: {
            'Authorization': `JWT ${token}`,
            "Supplier": "1"
          }
        }).toPromise()
      }
      else{
        return null
      }
    })
  }

  getSampleAndQuoteRequestsForBrands() {
    return this.verifyToken().then((token: any) => {
      if (token) {
        return this.http.post<any>(`${backend}get-samples-and-quotes`, {}, {
          headers: {
            'Authorization': `JWT ${token}`
          }
        }).toPromise()
      }
      else{
        return null
      }
    })
  }

  getSupplierQuoteRequestForBrandById(id: string) {
    return this.verifyToken().then((token: any) => {
      if (token) {
        return this.http.post(`${backend}get-quote-request-by-id`, { id }, {
          headers: {
            "Authorization": `JWT ${token}`
          }
        }).toPromise()
      }
      else{
        return null
      }
    })
  }

  getSupplierSampleRequestForBrandById(id: string) {
    return this.verifyToken().then((token: any) => {
      if (token) {
        return this.http.post(`${backend}get-sample-request-by-id`, { id }, {
          headers: {
            "Authorization": `JWT ${token}`
          }
        }).toPromise()
      }
      else{
        return null
      }
    })
  }

  getFullMarketplaceProfile(supplierId: string) {
    return this.verifyToken().then((token: any) => {
      if (token) {
        return this.http.post(`${backend}get-supplier-profile-for-brands`, { supplierId }, {
          headers: {
            "Authorization": `JWT ${token}`
          }
        }).toPromise()
      }
      else{
        return null
      }
    })
  }

  getBlogs() {
    let params: any = new HttpParams().set("pageNo", "1").set("pageSize", "10");
    return this.http.get<any>(`${backend}get-all-blogs`, {
      params: params
    }).toPromise();
  }

  getBlogsByID(blog_id: string = "") {
    let params: any = new HttpParams().set("blogId", blog_id);
    return this.http.get<any>(`${backend}get-blog-by-id`, {
      params: params
    }).toPromise();
  }

  createBlogs(body: any) {
    return this.verifyToken().then((token: any) => {
      if (token) {
        return this.http.post(`${backend}admin/create-blog`, body, {
          headers: {
            "Authorization": `JWT ${token}`
          }
        }).toPromise()
      }
      else{
        return null
      }
    })
  }

  updateBlogs(body: any) {
    return this.verifyToken().then((token: any) => {
      if (token) {
        return this.http.post(`${backend}admin/update-blog`, body, {
          headers: {
            "Authorization": `JWT ${token}`
          }
        }).toPromise()
      }
      else{
        return null
      }
    })
  }

  deleteBlog(id: any) {
    const params: any = {
      blogId: id
    }
    return this.verifyToken().then((token: any) => {
      if (token) {
        return this.http.delete<any>(`${backend}admin/delete-blog`, {
          headers: {
            "Authorization": `JWT ${token}`
          },
          params: params
        }).toPromise()
      }
      else{
        return null
      }
    })
  }

  createProductForProject(project: Project, productDetails: ProductDetails) {
    return this.verifyToken().then((token: any) => {
      if (token) {
        let file: File
        if(productDetails.haveProductImage && productDetails.productImageFile){
          file = productDetails.productImageFile
        }
        else{
          productDetails.haveProductImage = false
        }
        return this.http.post<any>(`${backend}virtual-lab/create-product`, {
          projectId: project._id,
          data: productDetails
        }, {
          headers: {
            "Authorization": `JWT ${token}`
          }
        }).toPromise().then(async (result: any) => {
          if(file){
            let formData: FormData = new FormData();
            formData.append('file', file, file.name);
            formData.append('projectId', project._id.toString())
            formData.append('productId', result.productId)
            await this.uploadProductImage(formData).catch((err: any) => {
              this.toastr.error("An error occurred while uploading the product image. Please try again.")
            })
            return result
          }
          else
            return result
          
        })
      }
      else{
        return null
      }
    })
  }

  getProjectDetails(projectId: String, searchQuery: String = "", pageNo: number = 0){
    return this.verifyToken().then((token: any) => {
      if (token) {
        return this.http.post<Project>(`${backend}virtual-lab/get-project-details`, {
          projectId,
          searchQuery,
          pageNo
        }, {
          headers: {
            "Authorization": `JWT ${token}`
          }
        }).toPromise()
      }
      else{
        return null
      }
    })
  }

  getProductDetails(projectId: String, productId: String) {
    return this.verifyToken().then((token: any) => {
      if (token) {
        return this.http.post<any>(`${backend}virtual-lab/get-product-details`, {
          productId,
          projectId
        }, {
          headers: {
            "Authorization": `JWT ${token}`
          }
        }).toPromise()
      }
      else{
        return null
      }
    })
  }

  getProductFormulas(projectId: String, productId: String) {
    return this.verifyToken().then((token: any) => {
      if (token) {
        return this.http.post<any>(`${backend}virtual-lab/get-product-formulas`, {
          productId,
          projectId
        }, {
          headers: {
            "Authorization": `JWT ${token}`
          }
        }).toPromise()
      }
      else{
        return null
      }
    })
  }

  getFormula(projectId: String, productId: String, formulaId: String) {
    return this.verifyToken().then((token: any) => {
      if (token) {
        return this.http.post<any>(`${backend}virtual-lab/get-product-formula`, {
          productId,
          projectId,
          formulaId
        }, {
          headers: {
            "Authorization": `JWT ${token}`
          }
        }).toPromise()
      }
      else{
        return null
      }
    })
  }

  getTextbookFormula(projectId: String, productId: String, formulaId: String) {
    return this.verifyToken().then((token: any) => {
      if (token) {
        return this.http.post<any>(`${backend}virtual-lab/get-textbook-formula`, {
          productId,
          projectId,
          formulaId
        }, {
          headers: {
            "Authorization": `JWT ${token}`
          }
        }).toPromise()
      }
      else{
        return null
      }
    })
  }

  addIngredientToProductFormula(projectId: String, productId: String, formulaId: String, ingredientDetails: any, searchQuery: any) {
    return this.verifyToken().then((token: any) => {
      if (token) {
        return this.http.post<any>(`${backend}virtual-lab/add-ingredient-to-product-formula`, {
          productId,
          projectId,
          formulaId,
          ingredientDetails,
          searchQuery
        }, {
          headers: {
            "Authorization": `JWT ${token}`
          }
        }).toPromise()
      }
      else{
        return null
      }
    })
  }

  updateProductFormula(projectId: String, productId: String, formulaId: String, updateObject: any, logKey: String, logOldVal: any, logNewVal: any) {
    return this.verifyToken().then((token: any) => {
      if (token) {
        return this.http.post<any>(`${backend}virtual-lab/update-product-formula`, {
          productId,
          projectId,
          formulaId,
          updateObject,
          logKey,
          logOldVal,
          logNewVal
        }, {
          headers: {
            "Authorization": `JWT ${token}`
          }
        }).toPromise()
      }
      else{
        return null
      }
    })
  }

  submitProductFormula(projectId: String, productId: String, formulaId: String, score: Number, sectionWiseIngredients: any) {
    return this.verifyToken().then((token: any) => {
      if (token) {
        return this.http.post<any>(`${backend}virtual-lab/submit-product-formula`, {
          productId,
          projectId,
          formulaId,
          safetyScore: score,
          sectionWiseIngredients
        }, {
          headers: {
            "Authorization": `JWT ${token}`
          }
        }).toPromise()
      }
      else{
        return null
      }
    })
  }

  deleteProduct(projectId: String, productId: String) {
    return this.verifyToken().then((token: any) => {
      if (token) {
        return this.http.post<any>(`${backend}virtual-lab/delete-product`, {
          projectId,
          productId
        }, {
          headers: {
            "Authorization": `JWT ${token}`
          }
        }).toPromise()
      }
      else{
        return null
      }
    })
  }

  deleteProductFormula(projectId: String, productId: String, formulaId: String){
    return this.verifyToken().then((token: any) => {
      if (token) {
        return this.http.post<any>(`${backend}virtual-lab/delete-product-formula`, {
          projectId,
          productId,
          formulaId
        }, {
          headers: {
            "Authorization": `JWT ${token}`
          }
        }).toPromise()
      }
      else{
        return null
      }
    })
  }

  copyProductFormula(originalFormulaData: any, projectId: String, productId: String, newFormulaName: String){
    return this.verifyToken().then((token: any) => {
      if (token) {
        return this.http.post<any>(`${backend}virtual-lab/copy-product-formula`, {
          originalFormulaData,
          projectId,
          productId,
          newFormulaName
        }, {
          headers: {
            "Authorization": `JWT ${token}`
          }
        }).toPromise()
      }
      else{
        return null
      }
    })
  }

  uploadProductImage(formData: FormData) {
    return this.verifyToken().then((token: any) => {
      if (token) {
        return this.http.post<any>(`${backend}virtual-lab/upload-product-image`, formData, {
          headers: {
            'Authorization': `JWT ${token}`
          }
        }).toPromise()
      }
      else{
        return null
      }
    })
  }

  updateProject(projectId: String, updateObject: any, logKey: String, logOldVal: any, logNewVal: any){
    return this.verifyToken().then((token: any) => {
      if (token) {
        return this.http.post<any>(`${backend}virtual-lab/update-project`, {
          projectId,
          updateObject,
          logKey,
          logOldVal,
          logNewVal
        }, {
          headers: {
            "Authorization": `JWT ${token}`
          }
        }).toPromise()
      }
      else{
        return null
      }
    })
  }

  updateProductDetails(projectId: String, productId: String, updateObject: any, logKey: String, logOldVal: any, logNewVal: any){
    return this.verifyToken().then((token: any) => {
      if (token) {
        return this.http.post<any>(`${backend}virtual-lab/update-product-details`, {
          projectId,
          productId,
          updateObject,
          logKey,
          logOldVal,
          logNewVal
        }, {
          headers: {
            "Authorization": `JWT ${token}`
          }
        }).toPromise()
      }
      else{
        return null
      }
    })
  }

  getUserCustomIngredientDatabase(userType: string = "brand"){
    return this.verifyToken(false, userType).then((token: any) => {
      if (token) {
        let headers: any = {
          "Authorization": `JWT ${token}`
        }
        if(userType == "supplier"){
          headers["Supplier"] = "1"
        }
        return this.http.get<any>(`${backend}database/get-user-database-id`, {
          headers: headers
        }).toPromise()
      }
      else{
        return null
      }
    })
  }

  getIngredientDetails(prodevId: Number, searchQuery?: SearchQueryObject, admin?: Boolean, supplier?: Boolean) {
    let userType: any = "brand"
    if(supplier){ 
      userType = "supplier"
    }
    return this.verifyToken(false, userType).then((token: any) => {
      if (token) {
        let headers: any = {
          "Authorization": `JWT ${token}`
        }
        if(supplier){
          headers["Supplier"] = "1"
        }
        return this.http.post<any>(`${backend}ingredients/get-ingredient`, {
          prodevId,
          searchQuery,
          admin
        }, {
          headers: headers
        }).toPromise()
      }
      else{
        return null
      }
    })
  }

  createInvDatabase(body: any, userType: "brand" | "supplier") {
    body["userType"] = userType
    return this.verifyToken(false, userType).then((token: any) => {
      if (token) {
        let headers: any = {
          "Authorization": `JWT ${token}`
        }
        if(userType=="supplier"){
          headers["Supplier"] = "1"
        }
        return this.http.put<any>(`${backend}database/create-database`, body, {
          headers: headers
        }).toPromise()
      }
      else{
        return null
      }
    })
  }

  getAcuteSafetyInformationOfFormula(prodevIds: Number[]){
    return this.verifyToken().then((token: any) => {
      if (token) {
        return this.http.post<any>(`${backend}virtual-lab/get-acute-safety-information`, {
          prodevIds
        }, {
          headers: {
            "Authorization": `JWT ${token}`
          }
        }).toPromise()
      }
      else{
        return null
      }
    })
  }

  getUserInvDatabaseID() {
    return this.verifyToken().then((token: any) => {
      if (token) {
        return this.http.get<any>(`${backend}`, {
          headers: {
            "Authorization": `JWT ${token}`
          }
        }).toPromise()
      }
      else{
        return null
      }
    })
  }

  suggestIngredientsAI(existingFormula: Number[], productId: String, apiType: String){
    return this.verifyToken().then((token: any) => {
      if (token) {
        return this.http.post<any>(`${backend}virtual-lab/ai-suggestions`, {
          existingFormula,
          productId,
          apiType
        }, {
          headers: {
            "Authorization": `JWT ${token}`
          }
        }).toPromise()
      }
      else{
        return null
      }
    })
  }

  addBrandCommentToQuoteRequest(id: string, message: string){
    return this.verifySupplierToken().then(async res => {
      if (res) {
        return await this.http.post<any>(`${backend}add-supplier-quote-request-comment`, {
          id, message
        }, {
          headers: {
            "Authorization": `JWT ${res}`
          }
        }).toPromise()
      }
      else{
        return null
      }
    })
  }


  addBrandCommentToSampleRequest(id: string, message: string){
    return this.verifySupplierToken().then(async res => {
      if (res) {
        return await this.http.post<any>(`${backend}add-supplier-sample-request-comment`, {
          id, message
        }, {
          headers: {
            "Authorization": `JWT ${res}`
          }
        }).toPromise()
      }
      else{
        return null
      }
    })
  }

  addSupplierCommentToQuoteRequest(id: string, message: string){
    return this.verifySupplierToken().then(async res => {
      if (res) {
        return await this.http.post<any>(`${backend}supplier/add-quote-request-comment`, {
          id, message
        }, {
          headers: {
            "Authorization": `JWT ${res}`,
            'Supplier': '1'
          }
        }).toPromise()
      }
      else{
        return null
      }
    })
  }


  addSupplierCommentToSampleRequest(id: string, message: string){
    return this.verifySupplierToken().then(async res => {
      if (res) {
        return await this.http.post<any>(`${backend}supplier/add-sample-request-comment`, {
          id, message
        }, {
          headers: {
            "Authorization": `JWT ${res}`,
            'Supplier': '1'
          }
        }).toPromise()
      }
      else{
        return null
      }
    })
  }

  uploadSupplierCertificateFile(file: File) {
    return this.verifySupplierToken().then((token: any) => {
      let formdata: any = new FormData()
      formdata.append("file", file)
      if (token) {
        return this.http.post(`${backend}supplier/upload-certificate-file`, formdata, {
          headers: {
            'Authorization': `JWT ${token}`,
            'Supplier': '1',
          }
        }).toPromise()
      }
      else{
        return null
      }
    })
  }

  downloadSupplierCertificateFile(filename: string) {
    return this.verifySupplierToken().then((token: any) => {
      if (token) {
        console.log("Downloading file => ", filename)
        return this.http.post(`${backend}supplier/download-certificate-file`, {filename}, {
          headers: {
            'Authorization': `JWT ${token}`,
            'Supplier': '1'
          },
          responseType: 'blob'
        }).toPromise()
      }
      else{
        return null
      }
    })
  }

  deleteSupplierCertificateFile(filename: string) {
    return this.verifySupplierToken().then((token: any) => {
      if (token) {
        return this.http.post(`${backend}supplier/delete-certificate-file`, {filename}, {
          headers: {
            'Authorization': `JWT ${token}`,
            'Supplier': '1',
          }
        }).toPromise()
      }
      else{
        return null
      }
    })
  }

  uploadSupplierMarkettingFile(fileType: string, file: File) {
    return this.verifySupplierToken().then((token: any) => {
      let formdata: any = new FormData()
      formdata.append("file", file)
      formdata.append("filetype", fileType)
      if (token) {
        return this.http.post(`${backend}supplier/upload-marketting-file`, formdata, {
          headers: {
            'Authorization': `JWT ${token}`,
            'Supplier': '1'
          },
          reportProgress: true
        }).toPromise()
      }
      else{
        return null
      }
    })
  }

  downloadSupplierMarkettingFile(filetype: string, filename: string) {
    return this.verifySupplierToken().then((token: any) => {
      if (token) {
        return this.http.post(`${backend}supplier/download-marketting-file`, {
          filename,
          filetype
        }, {
          headers: {
            'Authorization': `JWT ${token}`,
            'Supplier': '1'
          },
          responseType: 'blob'
        }).toPromise()
      }
      else{
        return null
      }
    })
  }

  deleteSupplierMarkettingFile(filetype: string, filename: string) {
    return this.verifySupplierToken().then((token: any) => {
      if (token) {
        return this.http.post(`${backend}supplier/delete-marketting-file`, {
          filename,
          filetype
        }, {
          headers: {
            'Authorization': `JWT ${token}`,
            'Supplier': '1',
          }
        }).toPromise()
      }
      else{
        return null
      }
    })
  }

  downloadMarkettingFileForBrands(filename: string, filetype: string, supplierId: string) {
    return this.verifyToken().then((token: any) => {
      if (token) {
        return this.http.post(`${backend}download-supplier-marketting-file`, {
          filename,
          filetype,
          supplierId
        }, {
          headers: {
            'Authorization': `JWT ${token}`,
            Accept: "application/octet-stream"
          },
          responseType: 'blob'
        }).toPromise()
      }
      else{
        return null
      }
    })
  }

  downloadSupplierCertificateFileForBrands(supplierId: string, filename: string) {
    return this.verifyToken().then((token: any) => {
      if (token) {
        return this.http.post(`${backend}download-supplier-certificate-file`, {
          supplierId, 
          filename
        }, {
          headers: {
            'Authorization': `JWT ${token}`,
            Accept: "application/octet-stream"
          },
          responseType: 'blob'
        }).toPromise()
      }
      else{
        return null
      }
    })
  }

  insertIngredientInInvDB() {
    // const url: any = database/insert-ingredient;
  }

  deleteUserInvDB() {
    // database/delete?id={{databaseId}}
  }

  getInvDB(url: string, user: string, params?: any) {
    if(!params){
      params = {}
    }
    params["userType"] = user
    return this.verifyToken(false, user).then((token: any) => {
      if (token) {
        let headers: any = {
          "Authorization": `JWT ${token}`,
        }
        if(user=="supplier"){
          headers["Supplier"] = "1"
        }
        return this.http.get<any>(`${backend + this.invDBURL[url]}`, {
          headers: headers,
          params: params
        }).toPromise()
      }
      else{
        return null
      }
    })
  }

  getDbFormat(user: string) {
    return new Promise((resolve: any,     reject: any) => {
      this.verifyToken(false, user).then((token: any) => {
        if (token) {
          let headers: any = {
            "Authorization": `JWT ${token}`,
            Accept: "application/octet-stream"
          }
          if(user=="supplier"){
            headers["Supplier"] = "1"
          } return this.http.get(`${backend}database/ingredient-upload-format`, {
            headers: headers,
            responseType: 'blob'
          }).toPromise().then((res: any) => {
            resolve(res)
          }, err => {
            reject((err))
          })
        }
        else{
          return null
        }
      })
    })
  }

  postInvDB(url: string, user: string, body?: any) {
    if(!body)
      body = {}
    body['userType'] = user
    return new Promise((resolve: any,     reject: any) => {
      this.verifyToken(false, user).then((token: any) => {
        let headers: any = {
          "Authorization": `JWT ${token}`,
        }
        if(user=="supplier"){
          headers["Supplier"] = "1"
        }
        if (token) {
          return this.http.post<any>(`${backend + this.invDBURL[url]}`, body, {
            headers: headers
          }).toPromise().then((res: any) => {
            resolve(res)
          }, err => {
            reject((err))
          })
        }
        else{
          return null
        }
      })
    })
  }

  deleteInvDB(url: string, user: string, params?: any) {
    if(!params){
      params = {}
    }
    params["userType"] = user
    return new Promise((resolve: any,     reject: any) => {
      this.verifyToken(false, user).then((token: any) => {
        if (token) {
          let headers: any = {
            "Authorization": `JWT ${token}`,
          }
          if(user=="supplier"){
            headers["Supplier"] = "1"
          }
          return this.http.delete<any>(`${backend + this.invDBURL[url]}`, {
            headers: headers,
            params: params
          }).toPromise().then((res: any) => {
            resolve(res)
          }, err => {
            reject((err))
          })
        }
        else{
          return null
        }
      })
    })
  }

  submitSupplierSupportForm(supportForm: any){
    return this.verifySupplierToken().then((token: any) => {
      if (token) {
        return this.http.post(`${backend}supplier/raise-ticket`, supportForm,  {
          headers: {
            "Authorization": `JWT ${token}`,
            "Supplier": "1"
          }
        }).toPromise()
      }
      else{
        return null
      }
    })
  }

  getAllSupplierSupportTickets(){
    return this.verifySupplierToken().then((token: any) => {
      if (token) {
        return this.http.post(`${backend}supplier/get-all-tickets`, {},  {
          headers: {
            "Authorization": `JWT ${token}`,
            "Supplier": "1"
          }
        }).toPromise()
      }
      else{
        return null
      }
    })
  }

  closeSupplierSupportTicket(ticket: any){
    return this.verifySupplierToken().then((token: any) => {
      if (token) {
        return this.http.post(`${backend}supplier/close-ticket`, {
          ticketId: ticket._id
        }, {
          headers: {
            "Authorization": `JWT ${token}`,
            "Supplier": "1"
          }
        }).toPromise()
      }
      else{
        return null
      }
    })
  }

  getSupplierSupportTicketById(ticketId: string){
    return this.verifySupplierToken().then((token: any) => {
      if (token) {
        return this.http.post(`${backend}supplier/get-ticket`, {
          ticketId
        }, {
          headers: {
            "Authorization": `JWT ${token}`,
            "Supplier": "1"
          }
        }).toPromise()
      }
      else{
        return null
      }
    })
  }

  sendSupplierSupportTicketMessage(ticketId: string, message: string){
    return this.verifySupplierToken().then((token: any) => {
      if (token) {
        return this.http.post(`${backend}supplier/add-message-to-ticket`, {
          ticketId,
          message
        }, {
          headers: {
            "Authorization": `JWT ${token}`,
            "Supplier": "1"
          }
        }).toPromise()
      }
      else{
        return null
      }
    })
  }

  getAllProjects(pageNo: number = 0, searchQuery: string = ""){
    return this.verifyToken().then((token: any) => {
      if (token) {
        return this.http.post<{count: number, data: Project[], planCompletion: any}>(`${backend}virtual-lab/get-all-projects`, {
          pageNo,
          searchQuery
        }, {
          headers: {
            "Authorization": `JWT ${token}`
          }
        }).toPromise()
      }
      else{
        return null
      }
    })
  }

  createNewProject(data: any){
    return this.verifyToken().then((token: any) => {
      if (token) {
        return this.http.post(`${backend}virtual-lab/create-project`, { data }, {
          headers: {
            "Authorization": `JWT ${token}`
          }
        }).toPromise()
      }
      else{
        return null
      }
    })
  }

  getProofFilesRequest(formId: string) {
    return this.verifyToken().then((token: any) => {
      if (token) {
        return this.http.post<any>(`${backend}get-supplier-proof-files-request`, { formId }, {
          headers: {
            "Authorization": `JWT ${token}`
          }
        }).toPromise()
      }
      else{
        return null
      }
    })
  }

  saveSupplierPlanDetails(allPlans: any[], applyToExistingUser: boolean) {
    return this.verifyToken().then((token: any) => {
      if (token) {
        return this.http.post(`${backend}admin/save-supplier-plans`, {
          allPlans,
          applyToExistingUser
        }, {
          headers: {
            'Authorization': `JWT ${token}`
          }
        }).toPromise()
      }
      else{
        return null
      }
    })
  }


  filterBlogListByTags(body: any) {
    return this.http.post(`${backend}get-all-blogs-filtered`, body).toPromise()
  }

  addBrandToSupplierIngredientViewHistory(formId: string){
    return this.verifyToken().then((token: any) => {
      if (token) {
        return this.http.post(`${backend}add-brand-to-supplier-ingredient-history`, { formId }, {
          headers: {
            "Authorization": `JWT ${token}`
          }
        }).toPromise()
      }
      else{
        return null
      }
    })
  }
  
  requestProofFiles(formId: string, supplierId: string, requestMessage: {companyName: string, message: string}) {
    return this.verifyToken().then(async res => {
      if (res) {
        return this.http.post<any>(`${backend}sumbit-supplier-proof-files-request`, {
          formId,
          supplierId,
          requestMessage
        }, {
          headers: {
            "Authorization": `JWT ${res}`
          }
        }).toPromise()
      }
      else {
        return null
      }
    })
  }

  
  getAllProofFileRequests() {
    return this.verifySupplierToken().then((token: any) => {
      if (token) {
        return this.http.get<ProofFilesRequest[]>(`${backend}supplier/get-all-proof-file-requests`, {
          headers: {
            'Authorization': `JWT ${token}`,
            'Supplier': '1'
          }
        }).toPromise()
      }
      else{
        return null
      }
    })
  }

  getProofFileRequest(requestId: string) {
    return this.verifySupplierToken().then((token: any) => {
      if (token) {
        return this.http.post<any>(`${backend}supplier/get-proof-file-request`, {requestId}, {
          headers: {
            'Authorization': `JWT ${token}`,
            'Supplier': '1'
          }
        }).toPromise()
      }
      else{
        return null
      }
    })
  }

  approveProofFileRequest(requestId: string) {
    return this.verifySupplierToken().then((token: any) => {
      if (token) {
        return this.http.post(`${backend}supplier/accept-proof-file-request`, {requestId}, {
          headers: {
            'Authorization': `JWT ${token}`,
            'Supplier': '1'
          }
        }).toPromise()
      }
      else{
        return null
      }
    })
  }

  rejectProofFileRequest(requestId: string, rejectMessage: string) {
    return this.verifySupplierToken().then((token: any) => {
      if (token) {
        return this.http.post(`${backend}supplier/reject-proof-file-request`, {requestId, rejectMessage}, {
          headers: {
            'Authorization': `JWT ${token}`,
            'Supplier': '1'
          }
        }).toPromise()
      }
      else{
        return null
      }
    })
  }

  downloadMarkplaceLogo(supplierId: string, userType: "supplier" | "user"){
    return this.verifyToken(false, userType).then((token: any) => {
      if (token) {
        let headers: any = {
          "Authorization": `JWT ${token}`,
          Accept: "application/octet-stream"
        }
        let url: any = "download-marketplace-logo"
        if(userType == "supplier"){
          headers["Supplier"] = "1"
          url = "supplier/" + url
        }
        return this.http.post(`${backend}${url}`, {
          supplierId
        }, {
          headers: headers,
          responseType: 'blob'
        }).toPromise()
      }
      else{
        return null
      }
    })
  }

  getMarketplaceProfile(marketplaceId: string){
    return this.http.get(`${backend}public/get-marketplace-profile?marketplaceId=${marketplaceId}`).toPromise()
  }

  addDatabaseIngredientToForm(formId: string, ingredient: any){
    return this.verifySupplierToken().then((token: any) => {
      if (token) {
        return this.http.post(`${backend}supplier/add-db-ingredient-to-form`, {formId, ingredient}, {
          headers: {
            'Authorization': `JWT ${token}`,
            'Supplier': '1'
          }
        }).toPromise()
      }
      else{
        return null
      }
    })
  }

  getSupplierStatistics() {
    return this.verifySupplierToken().then((token: any) => {
      if (token) {
        return this.http.get<any>(`${backend}supplier/statistics`, {
          headers: {
            'Authorization': `JWT ${token}`,
            'Supplier': '1'
          }
        }).toPromise()
      }
      else{
        return null
      }
    })
  }

  getAllCustomInputs(){
    return this.verifySupplierToken().then((token: any) => {
      if (token) {
        return this.http.get<any>(`${backend}supplier/get-all-custom-inputs`, {
          headers: {
            "Authorization": `JWT ${token}`,
            "Supplier": "1"
          }
        }).toPromise()
      }
      else{
        return null
      }
    })
  }

  addCustomInput(key: string, value: string){
    return this.verifySupplierToken().then((token: any) => {
      if (token) {
        return this.http.post<any>(`${backend}supplier/add-custom-input`, {
          key, value
        }, {
          headers: {
            "Authorization": `JWT ${token}`,
            "Supplier": "1"
          }
        }).toPromise()
      }
      else{
        return null
      }
    })
  }

  removeCustomInput(key: string, value: string){
    return this.verifySupplierToken().then((token: any) => {
      if (token) {
        return this.http.post<any>(`${backend}supplier/remove-custom-input`, {
          key, value
        }, {
          headers: {
            "Authorization": `JWT ${token}`,
            "Supplier": "1"
          }
        }).toPromise()
      }
      else{
        return null
      }
    })
  }

  uploadBlogDescriptionImage(file: any) {
    return this.verifyToken().then((token: any) => {
      if (token) {
        let formData: FormData = new FormData();
        formData.append('file', file, file.name);
        return this.http.post(`${backend}upload-profile-photo`, formData, {
          headers: {
            'Authorization': `JWT ${token}`
          }
        })
      }
      else{
        return null
      }
    })
  }  


  submitChatbotGrievance(questionare: any, grievance: any, demoBooked: boolean = false) {
    return this.http.post(`${backend}submit-chatbot-grievance`, {
      questionare,
      grievance,
      demoBooked
    }).toPromise()
  }

  setTempMarketplaceProfile(data: any){
    return this.verifySupplierToken().then((token: any) => {
      if (token) {
        return this.http.post(`${backend}supplier/temp-marketplace-profile`, {data}, {
          headers: {
            'Authorization': `JWT ${token}`,
            'Supplier': '1'
          }
        }).toPromise()
      }
      else{
        return null
      }
    })
  }

  likeBlog(blogId: string, userType: string){
    console.log(userType)
    return this.verifyToken(false, userType).then((token: any) => {
      if (token) {
        let headers: any = {
          "Authorization": `JWT ${token}`
        }
        if(userType == "supplier"){
          headers["Supplier"] = "1"
        }
        return this.http.post(`${backend}like-blog`, {
          blogId: blogId
        }, {
          headers
        }).toPromise()
      }
      else{
        return null
      }
    })
  }

  getTempMarketplaceProfile(){
    return this.verifySupplierToken().then((token: any) => {
      if (token) {
        return this.http.get(`${backend}supplier/temp-marketplace-profile`, {
          headers: {
            'Authorization': `JWT ${token}`,
            'Supplier': '1'
          }
        }).toPromise()
      }
      else{
        return null
      }
    })
  }
  addBlogComment(blogId: string, comment: string, userType: string){
    return this.verifyToken(false, userType).then((token: any) => {
      if (token) {
        let headers: any = {
          "Authorization": `JWT ${token}`
        }
        if(userType == "supplier"){
          headers["Supplier"] = "1"
        }
        return this.http.post(`${backend}add-blog-comment`, {
          blogId,
          comment
        }, {
          headers
        }).toPromise()
      }
      else{
        return null
      }
    })
  }

  deleteTempMarketplaceProfile(){
    return this.verifySupplierToken().then((token: any) => {
      if (token) {
        return this.http.delete(`${backend}supplier/temp-marketplace-profile`, {
          headers: {
            'Authorization': `JWT ${token}`,
            'Supplier': '1'
          }
        }).toPromise()
      }
      else{
        return null
      }
    })
  }
  sendErrorLog(error: any, route: any, cookies: any){
    return this.http.post(`${backend}send-error-log`, {
      error,
      route,
      cookies
    }).toPromise()
  }

  uploadMarketplaceIngredientExcel(file: File) {
    return this.verifySupplierToken().then((token: any) => {
      let formdata: any = new FormData()
      formdata.append("file", file)
      if (token) {
        return this.http.post(`${backend}supplier/upload-ingredient-list-excel-file`, formdata, {
          headers: {
            'Authorization': `JWT ${token}`,
            'Supplier': '1',
          }
        }).toPromise()
      }
      else{
        return null
      }
    })
  }

  uploadMarketplaceIngredients(excelData: any[], excelFilename: string){
    return this.verifySupplierToken().then((token: any) => {
      if (token) {
        console.log(excelFilename)
        return this.http.post(`${backend}supplier/upload-ingredient-list-excel`, {
          excelData,
          excelFilename
        }, {
          headers: {
            "Authorization": `JWT ${token}`,
            "Supplier": "1"
          }
        }).toPromise()
      }
      else{
        return null
      }
    })
  }

  getAllMarketplaceIngredientExcelUploadData(){
    return this.verifySupplierToken().then((token: any) => {
      if (token) {
        return this.http.get(`${backend}supplier/all-marketplace-ingredient-uploads`, {
          headers: {
            "Authorization": `JWT ${token}`,
            "Supplier": "1"
          }
        }).toPromise()
      }
      else{
        return null
      }
    })
  }

  getMarketplaceIngredientUpload(uploadId: string){
    return this.verifySupplierToken().then((token: any) => {
      if (token) {
        return this.http.get(`${backend}supplier/marketplace-ingredient-upload?uploadId=${uploadId}`, {
          headers: {
            "Authorization": `JWT ${token}`,
            "Supplier": "1"
          }
        }).toPromise()
      }
      else{
        return null
      }
    })
  }

  downloadMarketplaceIngredientUploadTemplate(){
    return this.verifySupplierToken().then((token: any) => {
      if (token) {
        return this.http.get(`${backend}supplier/upload-ingredient-excel-format`, {
          headers: {
            "Authorization": `JWT ${token}`,
            "Supplier": "1"
          },
          responseType: 'blob'
        }).toPromise()
      }
      else{
        return null
      }
    })
  }


  updateMarketplaceIngredientUpload(uploadId: string, allIngredients: any[]){
    return this.verifySupplierToken().then((token: any) => {
      if (token) {
        return this.http.post(`${backend}supplier/update-marketplace-ingredient-upload`, {
          uploadId,
          allIngredients
        }, {
          headers: {
            "Authorization": `JWT ${token}`,
            "Supplier": "1"
          }
        }).toPromise()
      }
      else{
        return null
      }
    })
  }

  uploadMarketplaceIngredientUploadFile(uploadId: string, ingredientId: string, ingredientIndex: string, uploadType: "docs" | "certificates", file: File) {
    return this.verifySupplierToken().then((token: any) => {
      let formdata: any = new FormData()
      formdata.append('uploadId', uploadId)
      formdata.append('ingredientId', ingredientId)
      formdata.append('ingredientIndex', ingredientIndex)
      formdata.append('uploadType', uploadType)
      formdata.append("file", file)
      if (token) {
        return this.http.post(`${backend}supplier/marketplace-upload-file`, formdata, {
          headers: {
            'Authorization': `JWT ${token}`,
            'Supplier': '1',
          }
        }).toPromise()
      }
      else{
        return null
      }
    })
  }

  downloadMarketplaceIngredientUploadFile(uploadId: string, ingredientId: string, uploadType: "docs" | "certificates", fileName: string) {
    return this.verifySupplierToken().then((token: any) => {
      if (token) {
        return this.http.get(`${backend}supplier/marketplace-upload-file`, {
          params: {
            uploadId,
            ingredientId,
            uploadType,
            fileName
          },
          headers: {
            'Authorization': `JWT ${token}`,
            'Supplier': '1'
          },
          responseType: 'blob'
        }).toPromise()
      }
      else{
        return null
      }
    })
  }

  downloadMarketplaceIngredientFile(ingredientId: String, uploadType: "docs" | "certificates", filename: string) {
    return this.verifySupplierToken().then((token: any) => {
      if (token) {
        return this.http.post(`${backend}marketplace/download-marketplace-ingredient-file`, {
          ingredientId,
          uploadType,
          filename
        }, {
          headers: {
            'Authorization': `JWT ${token}`,
            'Supplier': '1'
          },
          responseType: 'blob'
        }).toPromise()
      }
      else{
        return null
      }
    })
  }

  deleteMarketplaceIngredientUploadFile(uploadId: string, ingredientId: string, ingredientIndex: string,
    fileIndex: string, uploadType: "docs" | "certificates", fileName: string) {
    return this.verifySupplierToken().then((token: any) => {
      if (token) {
        return this.http.delete(`${backend}supplier/marketplace-upload-file`, {
          params: {
            uploadId,
            ingredientId,
            ingredientIndex,
            uploadType,
            fileIndex,
            fileName
          },
          headers: {
            'Authorization': `JWT ${token}`,
            'Supplier': '1'
          }
        }).toPromise()
      }
      else{
        return null
      }
    })
  }

  submitMarketplaceIngredientUpload(uploadId: string){
    return this.verifySupplierToken().then((token: any) => {
      if (token) {
        return this.http.post(`${backend}supplier/submit-marketplace-ingredients-upload`, {
          uploadId
        }, {
          headers: {
            'Authorization': `JWT ${token}`,
            'Supplier': '1'
          }
        }).toPromise()
      }
      else{
        return null
      }
    })
  }

  discardMarketplaceIngredientUpload(uploadId: string){
    return this.verifySupplierToken().then((token: any) => {
      if (token) {
        return this.http.post(`${backend}supplier/discard-marketplace-ingredients-upload`, {
          uploadId
        }, {
          headers: {
            'Authorization': `JWT ${token}`,
            'Supplier': '1'
          }
        }).toPromise()
      }
      else{
        return null
      }
    })
  }

  submitMarketplaceIngredient(data: MarketplaceIngredient){
    return this.verifySupplierToken().then(async token => {
      if (token) {
        console.log(token)
        console.log(data)
        // console.log(await this.http.get(`${backend}`).toPromise())
        return this.http.post(`${backend}marketplace/submit-marketplace-ingredient`, {
          data
        }, {
          headers: {
            'Authorization': `JWT ${token}`,
            'Supplier': '1'
          }
        }).toPromise()
      }
      else{
        return null
      }
    })
  }

  saveMarketplaceIngredient(data: MarketplaceIngredient){
    return this.verifySupplierToken().then(async token => {
      if (token) {
        console.log(token)
        console.log(data)
        // console.log(await this.http.get(`${backend}`).toPromise())
        return this.http.post(`${backend}marketplace/save-marketplace-ingredient`, {
          data
        }, {
          headers: {
            'Authorization': `JWT ${token}`,
            'Supplier': '1'
          }
        }).toPromise()
      }
      else{
        return null
      }
    })
  }

  deleteMarketplaceIngredient(ingredientId: String){
    return this.verifySupplierToken().then((token: any) => {
      if (token) {
        return this.http.delete(`${backend}marketplace/delete-marketplace-ingredient`,{ 
          params: {
            ingredientId: ingredientId.toString()
          },
          headers: {
            'Authorization': `JWT ${token}`,
            'Supplier': '1'
          }
        }).toPromise()
      }
      else{
        return null
      }
    })
  }

  uploadMarketplaceIngredientFile(ingredientId: String, uploadType: "docs" | "certificates", file: File) {
    return this.verifySupplierToken().then((token: any) => {
      let formdata: any = new FormData()
      formdata.append('ingredientId', ingredientId.toString())
      formdata.append('uploadType', uploadType)
      formdata.append("file", file)
      if (token) {
        return this.http.post(`${backend}marketplace/upload-marketplace-ingredient-file`, formdata, {
          headers: {
            'Authorization': `JWT ${token}`,
            'Supplier': '1',
          }
        }).toPromise()
      }
      else{
        return null
      }
    })
  }

  deleteUploadedMarketplaceIngredientFile(ingredientId: String, uploadType: "docs" | "certificates", filename: String) {
    return this.verifySupplierToken().then((token: any) => {
      if (token) {
        return this.http.post(`${backend}marketplace/delete-uploaded-marketplace-ingredient-file`, {
          ingredientId: ingredientId,
          uploadType: uploadType,
          filename: filename
        }, {
          headers: {
            'Authorization': `JWT ${token}`,
            'Supplier': '1',
          }
        }).toPromise()
      }
      else{
        return null
      }
    })
  }

  getMarketplaceIngredient(ingredientId: String){
    return this.verifySupplierToken().then((token: any) => {
      if (token) {
        return this.http.get(`${backend}marketplace/get-marketplace-ingredient`, {
          params: {
            ingredientId: ingredientId.toString()
          },
          headers: {
            'Authorization': `JWT ${token}`,
            'Supplier': '1'
          }
        }).toPromise()
      }
      else{
        return null
      }
    })
  }

  isTradeNameUnique(name: string){
    return this.verifySupplierToken().then((token: any) => {
      if (token) {
        return this.http.post(`${backend}marketplace/is-trade-name-unique`, {
          tradeName: name
        }, {
          headers: {
            'Authorization': `JWT ${token}`,
            'Supplier': '1',
          }
        }).toPromise()
      }
      else{
        return null
      }
    })
  }

  sendQuotationToBrand(quoteId: String, quotationData: MarketplaceQuotationTest){
    return this.verifySupplierToken().then((token: any) => {
      if (token) {
        return this.http.post(`${backend}marketplace/send-quotation`, {
          quoteId,
          quotationData
        }, {
          headers: {
            'Authorization': `JWT ${token}`,
            'Supplier': '1',
          }
        }).toPromise()
      }
      else{
        return null
      }
    })
  }

  getMarketplaceQuoteRequests(pageNo: number, filters: any){
    return this.verifySupplierToken().then((token: any) => {
      if (token) {
        return this.http.post<any>(`${backend}marketplace/get-quote-requests`, {
          pageNo,
          filters
        }, {
          headers: {
            'Authorization': `JWT ${token}`,
            'Supplier': '1',
          }
        }).toPromise()
      }
      else{
        return null
      }
    })
  }

  getMarketplaceSampleRequests(pageNo: number, filters: any){
    return this.verifySupplierToken().then((token: any) => {
      if (token) {
        return this.http.post<any>(`${backend}marketplace/get-sample-requests`, {
          pageNo,
          filters
        }, {
          headers: {
            'Authorization': `JWT ${token}`,
            'Supplier': '1',
          }
        }).toPromise()
      }
      else{
        return null
      }
    })
  }
  rerunVirtualLabAI(projectId: any, productId: any, formulaId: any, otherClaims: any){
    return this.verifyToken().then((token: any) => {
      if (token) {
        return this.http.post(`${backend}virtual-lab/regenerate-formula`, {
          projectId,
          productId,
          formulaId,
          otherClaims
        }, {
          headers: {
            'Authorization': `JWT ${token}`
          }
        }).toPromise()
      }
      else{
        return null
      }
    })
  }

  getMarketplaceDashboardData(){
    return this.verifySupplierToken().then((token: any) => {
      if (token) {
        return this.http.get(`${backend}marketplace/dashboard-data`, {
          headers: {
            'Authorization': `JWT ${token}`,
            'Supplier': '1'
          }
        }).toPromise()
      }
      else{
        return null
      }
    })
  }

  provideAIFeedback(projectId: String, productId: String, formulaId: String, aiRunId: string, feedbackOf: "formula-generation" | "report-generation", 
    rating: number, comment: string) {
    return this.verifyToken().then((token: any) => {
      if (token) {
        return this.http.post(`${backend}virtual-lab/ai-feedback`, {
          projectId,
          productId,
          formulaId,
          aiRunId,
          feedbackOf,
          rating,
          comment
        }, {
          headers: {
            'Authorization': `JWT ${token}`
          }
        }).toPromise()
      }
      else{
        return null
      }
    })
  }

  getMarketplacesForChemical(prodevId: number) {
    return this.verifyToken().then((token: any) => {
      if (token) {
        return this.http.post<MarketplaceIngredient[]>(`${backend}virtual-lab/find-marketplace`, {
          prodevId
        }, {
          headers: {
            'Authorization': `JWT ${token}`
          }
        }).toPromise()
      }
      else{
        return null
      }
    })
  }
  
  getQuotation(quoteRequestId: string, quoteId: string){
    return this.verifySupplierToken().then((token: any) => {
      if (token) {
        return this.http.get<MarketplaceQuotation>(`${backend}marketplace/quotation`, {
          params: {
            quoteRequestId,
            quoteId
          },
          headers: {
            'Authorization': `JWT ${token}`,
            'Supplier': '1'
          }
        }).toPromise()
      }
      else{
        return null
      }
    })
  }

  getBrandDashboardStatistics(){
    return this.verifyToken().then((token: any) => {
      if (token) {
        return this.http.get<any>(`${backend}brand/statistics/dashboard`, {
          headers: {
            'Authorization': `JWT ${token}`
          }
        }).toPromise()
      }
      else{
        return null
      }
    })
  }

  getBrandChartStatistics(){
    return this.verifyToken().then((token: any) => {
      if (token) {
        return this.http.get<any>(`${backend}brand/statistics/charts`, {
          headers: {
            'Authorization': `JWT ${token}`
          }
        }).toPromise()
      }
      else{
        return null
      }
    })
  }

  getBrandProductStatistics(){
    return this.verifyToken().then((token: any) => {
      if (token) {
        return this.http.get<any>(`${backend}brand/statistics/product`, {
          headers: {
            'Authorization': `JWT ${token}`
          }
        }).toPromise()
      }
      else{
        return null
      }
    })
  }

  sendAUTHLog(logType: string, hastoken: boolean, userTypeRes: any, err: any){
    return this.http.post<any>(`${backend}log-authg`, {
      logType,
      hastoken,
      userTypeRes,
      err
    })
  }

  downloadProductImage(projectId: string, productId: string){
    return this.verifyToken().then((token: any) => {
      if (token) {
        return this.http.post(`${backend}virtual-lab/product-image`, {
          projectId,
          productId
        }, {
          headers: {
            "Authorization": `JWT ${token}`,
            "Accept": "application/octet-stream"
          },
          responseType: 'blob'
        }).toPromise()
      }
      else{
        return null
      }
    })
  }

  getMarketplaceListFromIds(ids: string[]){
    return this.verifyToken().then((token: any) => {
      if (token) {
        return this.http.post<any>(`${backend}brand/get-marketplace-list-by-id`, {ids}, {
          headers: {
            'Authorization': `JWT ${token}`
          }
        }).toPromise()
      }
      else{
        return null
      }
    })
  }

  updateMayaLabNotes(projectId: String, productId: String, formulaId: String, value: String) {
    return this.verifyToken().then((token: any) => {
      if (token) {
        return this.http.post<any>(`${backend}virtual-lab/update-maya-ai-lab-notes`, {
          projectId,
          productId,
          formulaId,
          note: value
        }, {
          headers: {
            'Authorization': `JWT ${token}`
          }
        }).toPromise()
      }
      else{
        return null
      }
    })
  }

  updateMayaReportNotes(projectId: String, productId: String, formulaId: String, value: any) {
    return this.verifyToken().then((token: any) => {
      if (token) {
        return this.http.post<any>(`${backend}virtual-lab/update-maya-ai-report-notes`, {
          projectId,
          productId,
          formulaId,
          note: value
        }, {
          headers: {
            'Authorization': `JWT ${token}`
          }
        }).toPromise()
      }
      else{
        return null
      }
    })
  }

  regenerateMAYAIThoughts(projectId: String, productId: String, formulaId: String){
    return this.verifyToken().then((token: any) => {
      if (token) {
        return this.http.post<any>(`${backend}virtual-lab/regenerate-mayai-thoughts`, {
          projectId,
          productId,
          formulaId
        }, {
          headers: {
            'Authorization': `JWT ${token}`
          }
        }).toPromise()
      }
      else{
        return null
      }
    })
  }

  regenerateSPFRating(projectId: String, productId: String, formulaId: String){
    return this.verifyToken().then((token: any) => {
      if (token) {
        return this.http.post<any>(`${backend}virtual-lab/regenerate-spf-rating`, {
          projectId,
          productId,
          formulaId
        }, {
          headers: {
            'Authorization': `JWT ${token}`
          }
        }).toPromise()
      }
      else{
        return null
      }
    })
  }


  submitBrandSupportForm(supportForm: any){
    return this.verifyToken().then((token: any) => {
      if (token) {
        return this.http.post(`${backend}brand/raise-ticket`, supportForm,  {
          headers: {
            "Authorization": `JWT ${token}`
          }
        }).toPromise()
      }
      else{
        return null
      }
    })
  }

  getAllBrandSupportTickets(){
    return this.verifyToken().then((token: any) => {
      if (token) {
        return this.http.post(`${backend}brand/get-all-tickets`, {},  {
          headers: {
            "Authorization": `JWT ${token}`
          }
        }).toPromise()
      }
      else{
        return null
      }
    })
  }

  closeBrandSupportTicket(ticket: any){
    return this.verifyToken().then((token: any) => {
      if (token) {
        return this.http.post(`${backend}brand/close-ticket`, {
          ticketId: ticket._id
        }, {
          headers: {
            "Authorization": `JWT ${token}`
          }
        }).toPromise()
      }
      else{
        return null
      }
    })
  }

  getBrandSupportTicketById(ticketId: string){
    return this.verifyToken().then((token: any) => {
      if (token) {
        return this.http.post(`${backend}brand/get-ticket`, {
          ticketId
        }, {
          headers: {
            "Authorization": `JWT ${token}`
          }
        }).toPromise()
      }
      else{
        return null
      }
    })
  }

  sendBrandSupportTicketMessage(ticketId: string, message: string){
    return this.verifyToken().then((token: any) => {
      if (token) {
        return this.http.post(`${backend}brand/add-message-to-ticket`, {
          ticketId,
          message
        }, {
          headers: {
            "Authorization": `JWT ${token}`
          }
        }).toPromise()
      }
      else{
        return null
      }
    })
  }

  copyformulaToProduct(originalProjectId: string, originalProductId: string, originalFormulaId: string, 
      projectId: string, productId?: string, formulaName?: string) {
    return this.verifyToken().then((token: any) => {
      if (token) {
        return this.http.post<any>(`${backend}virtual-lab/copy-formula-to-product`, {
          originalProjectId,
          originalProductId,
          originalFormulaId,
          projectId,
          productId,
          formulaName
        }, {
          headers: {
            "Authorization": `JWT ${token}`
          }
        }).toPromise()
      }
      else{
        return null
      }
    })
  }

  downloadMarketplaceCompanyLogo(marketplaceId: string, userType: "brand" | "supplier") {
    return this.verifyToken(false, userType).then((token: any) => {
      if (token) {
        let route: any = 'brand/download-marketplace-company-logo'
        let headers: any = {
          'Authorization': `JWT ${token}`,
          'Accept': "application/octet-stream",
        }
        if(userType == "supplier"){
          route = 'supplier/download-profile-photo'
          headers['Supplier'] = '1'
        }
        return this.http.post(`${backend}${route}`, {
          marketplaceId
        }, {
          headers: headers,
          responseType: 'blob'
        }).toPromise()
      }
      else{
        return null
      }
    })
  }
  regenerateMayaPerformance(projectId: String, productId: String, formulaId: String){
    return this.verifyToken().then((token: any) => {
      if (token) {
        return this.http.post<any>(`${backend}virtual-lab/regenerate-performance`, {
          projectId,
          productId,
          formulaId
        }, {
          headers: {
            'Authorization': `JWT ${token}`
          }
        }).toPromise()
      }
      else{
        return null
      }
    })
  }

  regenerateMayaStability(projectId: String, productId: String, formulaId: String){
    return this.verifyToken().then((token: any) => {
      if (token) {
        return this.http.post<any>(`${backend}virtual-lab/regenerate-stability`, {
          projectId,
          productId,
          formulaId
        }, {
          headers: {
            'Authorization': `JWT ${token}`
          }
        }).toPromise()
      }
      else{
        return null
      }
    })
  }

  regenerateMayaInnovationIndex(projectId: String, productId: String, formulaId: String){
    return this.verifyToken().then((token: any) => {
      if (token) {
        return this.http.post<any>(`${backend}virtual-lab/regenerate-innovation-index`, {
          projectId,
          productId,
          formulaId
        }, {
          headers: {
            'Authorization': `JWT ${token}`
          }
        }).toPromise()
      }
      else{
        return null
      }
    })
  }

  regenerateMayaMarketTrends(projectId: String, productId: String, formulaId: String){
    return this.verifyToken().then((token: any) => {
      if (token) {
        return this.http.post<any>(`${backend}virtual-lab/regenerate-market-trends`, {
          projectId,
          productId,
          formulaId
        }, {
          headers: {
            'Authorization': `JWT ${token}`
          }
        }).toPromise()
      }
      else{
        return null
      }
    })
  }


  getQuotationForBrand(quoteRequestId: string, quoteId: string) {
    return this.verifyToken().then((token: any) => {
      if (token) {
        return this.http.get<MarketplaceQuotation>(`${backend}brand/quotation`, {
          params: {
            quoteRequestId,
            quoteId
          },
          headers: {
            'Authorization': `JWT ${token}`
          }
        }).toPromise()
      }
      else{
        return null
      }
    })
  }

  revokeQuotation(quoteRequestId: String, quoteId: any) {
    return this.verifySupplierToken().then((token: any) => {
      if (token) {
        return this.http.post<any>(`${backend}marketplace/revoke-quotation`, {
          quoteRequestId,
          quoteId
        }, {
          headers: {
            'Authorization': `JWT ${token}`,
            "Supplier": '1'
          }
        }).toPromise()
      }
      else{
        return null
      }
    })
  }

  acceptQuotation(quoteRequestId: string, quoteId: string, closeQuotation: boolean) {
    return this.verifyToken().then((token: any) => {
      if (token) {
        return this.http.post<any>(`${backend}brand/accept-quotation`, {
          quoteRequestId,
          quoteId,
          closeQuotation
        }, {
          headers: {
            'Authorization': `JWT ${token}`
          }
        }).toPromise()
      }
      else{
        return null
      }
    })
  }

  rejectQuotation(quoteRequestId: string, quoteId: string, reason: any) {
    return this.verifyToken().then((token: any) => {
      if (token) {
        return this.http.post<any>(`${backend}brand/reject-quotation`, {
          quoteRequestId,
          quoteId,
          reason
        }, {
          headers: {
            'Authorization': `JWT ${token}`
          }
        }).toPromise()
      }
      else{
        return null
      }
    })
  } 

  getHealthConcernsIdsOfChemicals(prodevIds: number[]) {
    return this.verifyToken().then((token: any) => {
      if (token) {
        return this.http.post<any>(`${backend}virtual-lab/get-health-concerns`, {
          prodevIds
        }, {
          headers: {
            'Authorization': `JWT ${token}`
          }
        }).toPromise()
      }
      else{
        return null
      }
    })
  }

  downloadMarketplaceIngredientsExcelFileForSampleMapping() {
    return this.verifySupplierToken().then((token: any) => {
      if (token) {
        return this.http.get<any>(`${backend}marketplace/get-all-ingredients-for-sample-mapping`, {
          headers: {
            'Authorization': `JWT ${token}`,
            'Supplier': '1'
          }
        }).toPromise()
      }
      else{
        return null
      }
    })
  }

  submitProductEnquiryForm(data: any) {
    return this.http.post<any>(`${backend}raise-product-enquiry`, {
      data
    }).toPromise()
  }

  submitCustomProductPackage(data: any) {
    return this.http.post<any>(`${backend}submit-custom-product-package`, {
      data
    }).toPromise()
  }

  getWebinars(pageNo: number) {
    return this.http.post<any>(`${backend}webinar/get-all-webinars`, {pageNo}).toPromise()
  }

  getMarketplaceQuotesCost(marketplaces: any[]) {
    return this.verifyToken().then((token: any) => {
      if (token) {
        return this.http.post<any>(`${backend}virtual-lab/get-marketplace-quote-costs`, {
          marketplaces
        }, {
          headers: {
            'Authorization': `JWT ${token}`
          }
        }).toPromise()
      }
      else{
        return null
      }
    })
  }

  startLabTrialsProductFormula(projectId: String, productId: String, formulaId: String) {
    return this.verifyToken().then(token => {
      if (token) {
        return this.http.post<any>(`${backend}virtual-lab/start-product-formula-lab-trials`, {
          projectId,
          productId,
          formulaId
        }, {
          headers: {
            'Authorization': `JWT ${token}`
          }
        }).toPromise()
      }
    })
  }

  freezeProductFormula(projectId: String, productId: String, formulaId: String) {
    return this.verifyToken().then(token => {
      if (token) {
        return this.http.post<any>(`${backend}virtual-lab/freeze-product-formula`, {
          projectId,
          productId,
          formulaId
        }, {
          headers: {
            'Authorization': `JWT ${token}`
          }
        }).toPromise()
      }
    })
  }

  rejectProductFormula(projectId: String, productId: String, formulaId: String, rejectReason: any) {
    return this.verifyToken().then(token => {
      if (token) {
        return this.http.post<any>(`${backend}virtual-lab/reject-product-formula`, {
          projectId,
          productId,
          formulaId,
          rejectReason
        }, {
          headers: {
            'Authorization': `JWT ${token}`
          }
        }).toPromise()
      }
    })
  }

  rejectProductFormulaInLab(projectId: String, productId: String, formulaId: String, rejectReason: any) {
    return this.verifyToken().then(token => {
      if (token) {
        return this.http.post<any>(`${backend}virtual-lab/reject-product-formula-in-lab`, {
          projectId,
          productId,
          formulaId,
          rejectReason
        }, {
          headers: {
            'Authorization': `JWT ${token}`
          }
        }).toPromise()
      }
    })
  }
  
  getFilteredMAYAIFormulas(filters: any){
    return this.verifyToken().then((token: any) => {
      if (token) {
        return this.http.post<any>(`${backend}virtual-lab/get-filtered-formulas`, {
          filters
        }, {
          headers: {
            'Authorization': `JWT ${token}`
          }
        }).toPromise()
      }
      else{
        return null
      }
    })
  }

  submitPartnerForm(data: any) {
    return this.http.post<any>(`${backend}become-a-partner-form`, {
      data
    }).toPromise()
  }

  submitBrandPackageInterest(selectedPackage: string) {
    return this.verifyToken().then((token: any) => {
      if (token) {
        return this.http.post<any>(`${backend}brand/submit-package-interest`, {
          selectedPackage
        }, {
          headers: {
            'Authorization': `JWT ${token}`
          }
        }).toPromise()
      }
      else{
        return null
      }
    })
  }

  getAlternativeIngredients(projectId: string, productId: string, formulaId: string, formulaElement: FormulaElement) {
    return this.verifyToken().then((token: any) => {
      if (token) {
        return this.http.post<any>(`${backend}virtual-lab/get-alternative-ingredients`, {
          projectId,
          productId,
          formulaId,
          formulaElement
        }, {
          headers: {
            'Authorization': `JWT ${token}`
          }
        }).toPromise()
      }
      else{
        return null
      }
    })
  }

  createNewProductFormula(projectId: string, productId: string, newFormulaName: any) {
    return this.verifyToken().then((token: any) => {
      if (token) {
        return this.http.post<any>(`${backend}virtual-lab/create-new-formula`, {
          projectId,
          productId,
          newFormulaName
        }, {
          headers: {
            'Authorization': `JWT ${token}`
          }
        }).toPromise()
      }
      else{
        return null
      }
    })
  }
  
  registerForWebinar(value: any) {
    return this.http.post<any>(`${backend}register-for-webinar`, {
      data: value
    }).toPromise()
  }

  getWebinarPassById(passId: any) {
    return this.http.get<any>(`${backend}webinar-passes?passId=${passId}`).toPromise()
  }

  getCustomProductById(productId: any) {
    return this.http.get<any>(`${backend}custom-product-by-id?productId=${productId}`).toPromise()
  }

  submitCustomProductModification(formId: string, productId: string, data: any) {
    return this.http.post<any>(`${backend}modify-custom-product`, {
      formId,
      productId,
      data
    }).toPromise()
  }

  getGeoDetails() {
    return this.http.get("https://api.country.is").toPromise()
  }

  downloadMarketplaceCertificate(filename: string) {
    return this.verifySupplierToken().then((token: any) => {
      if (token) {
        return this.http.post(`${backend}supplier/download-certificate-file`, {
          filename
        }, {
          headers: {
            'Authorization': `JWT ${token}`,
            'Supplier': '1'
          },
          responseType: 'blob'
        }).toPromise()
       
      }
    })
  }

  downloadMarketplaceCertificateForBrands(filename: string, supplierId: string) {
    return this.verifyToken().then((token: any) => {
      if (token) {
        return this.http.post(`${backend}download-supplier-certificate`, {
          filename,
          supplierId
        }, {
          headers: {
            'Authorization': `JWT ${token}`,
            Accept: "application/octet-stream"
          },
          responseType: 'blob'
        }).toPromise()
      }
      else{
        return null
      }
    })
  }

  updateBannerUrl(banner: string, url: any) {
    return this.verifySupplierToken().then((token: any) => {
      if (token) {
        return this.http.post<any>(`${backend}supplier/update-banner-url`, {
          banner,
          url
        }, {
          headers: {
            'Authorization': `JWT ${token}`,
            'Supplier': '1'
          }
        }).toPromise()
      }
      else{
        return null
      }
    })
  }

  getBrandPackages(region: string, planExhausted: boolean) {
    return this.http.post<any>(`${backend}get-brand-packages`, {
      region,
      planExhausted
    }).toPromise()
  }

  changeBrandPlan(planId: string){
    return this.verifyToken().then(token => {
      if (token) {
        return this.http.post<any>(`${backend}brand/change-user-plan`, {
          planId
        }, {
          headers: {
            "Authorization": `JWT ${token}`
          }
        }).toPromise()
      }
    })
  }

  verifyOtherClaims(articleCategory: string, articleTexture: any, claim: any, otherClaims: string): any {
    return this.verifyToken().then(token => {
      if (token) {
        return this.http.post<any>(`${backend}virtual-lab/verify-other-claims`, {
          articleCategory,
          articleTexture,
          claim,
          otherClaims
        }, {
          headers: {
            "Authorization": `JWT ${token}`
          }
        }).toPromise()
      }
    })
  }

  
  getProductFormulasByIds(formulaIds: any) {
    return this.verifyToken().then(token => {
      if (token) {
        return this.http.post<any>(`${backend}virtual-lab/get-formulas-by-ids`, {
          formulaIds
        }, {
          headers: {
            "Authorization": `JWT ${token}`
          }
        }).toPromise()
      }
    })
  }

  getAllBrandNamesByUser(): any {
    return this.verifyToken().then(token => {
      if (token) {
        return this.http.get<any>(`${backend}virtual-lab/get-brand-names`, {
          headers: {
            "Authorization": `JWT ${token}`
          }
        }).toPromise()
      }
    })
  }


  createProductFormulaForProject(projectId: String, productId: String, formulaDetails: any) {
    return this.verifyToken().then((token: any) => {
      if (token) {
        return this.http.post<any>(`${backend}virtual-lab/create-product-formula-for-project`, {
          projectId, productId, formulaDetails
        }, {
          headers: {
            "Authorization": `JWT ${token}`
          }
        }).toPromise()
      }
      else{
        return null
      }
    })
  }

  generateFormulaPhases(projectId: string, productId: string, formulaId: string) {
    return this.verifyToken().then((token: any) => {
      if (token) {
        return this.http.post<any>(`${backend}virtual-lab/generate-formula-phases`, {
          projectId, productId, formulaId
        }, {
          headers: {
            "Authorization": `JWT ${token}`
          }
        }).toPromise()
      }
      else{
        return null
      }
    })
  }

}
